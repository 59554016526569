import { QueryClient, QueryKey } from '@tanstack/react-query'
import { call } from 'redux-saga/effects'

export const queryClient = new QueryClient()

// Generic function to generate query key
export function generateQueryKey<T extends Record<string, unknown>>(baseKey: string, payload: T): QueryKey {
    return [baseKey, ...Object.entries(payload).flat()]
}

// Generic function to fetch data with caching
export function* fetchDataWithCache<T, P extends Record<string, unknown>>(
    baseKey: string,
    payload: P,
    fetchFn: (params: P) => Promise<T>
): Generator<unknown, T, T> {
    const queryKey = generateQueryKey(baseKey, payload)
    let data: T | undefined = queryClient.getQueryData<T>(queryKey)

    if (!data) {
        data = yield call(() => queryClient.fetchQuery(queryKey, () => fetchFn(payload)))
    }

    return data
}

// Generic function to fetch data with caching
export async function fetchData<T, P extends Record<string, unknown>>(baseKey: string, payload: P, fetchFn: (params: P) => Promise<T>): Promise<T> {
    const queryKey = generateQueryKey(baseKey, payload)
    let data: T | undefined = queryClient.getQueryData<T>(queryKey)

    if (!data) {
        data = await queryClient.fetchQuery(queryKey, () => fetchFn(payload))
    }

    if (data === undefined) {
        throw new Error('Data could not be fetched and is undefined')
    }

    return data
}

export const CARD_ICONS = {
    patient_attributes: '👤',
    diagnosis: '🏥',
    medication: '💊',
    observation: '📊',
    lab: '🧪',
    note: '📝',
    observation_period: '📅',
    external_cohort: '👥',
    procedure: '⚕️'
}

export const TYPE_ICONS: Record<string, { icon: string; label: string }> = {
    ref_patient: { icon: CARD_ICONS.patient_attributes, label: 'Patient Attributes' },
    ref_diagnosis_boc_cui: { icon: CARD_ICONS.diagnosis, label: 'Diagnosis (BOC CUI)' },
    ref_diagnosis: { icon: CARD_ICONS.diagnosis, label: 'Diagnosis' },
    ref_medication_boc_cui: { icon: CARD_ICONS.medication, label: 'Medication (BOC CUI)' },
    ref_medication: { icon: CARD_ICONS.medication, label: 'Medication' },
    ref_medication_ndc: { icon: CARD_ICONS.medication, label: 'Medication (NDC)' },
    ref_observation: { icon: CARD_ICONS.observation, label: 'Observation' },
    ref_lab: { icon: CARD_ICONS.lab, label: 'Lab Test' },
    ref_note: { icon: CARD_ICONS.note, label: 'EHR Note' },
    ref_observation_period: { icon: CARD_ICONS.observation_period, label: 'Observation Period' },
    ref_external_cohort: { icon: CARD_ICONS.external_cohort, label: 'External Cohort' },
    ref_procedure: { icon: CARD_ICONS.procedure, label: 'Procedure' }
}
