/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { DateQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/DateQualifierDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC } from 'react'
import { determineLabel } from '../filters/RelativeDateFilter'
import { NodeContent } from '../nodes/NodeContent'

export const DateQualifier: FC<{
    qualifier: DateQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, parent, onClick, table, disableClick = false, qualifierIndex = -1 }) => {
    const label = determineLabel(qualifier as unknown as RelativeDateFilterDTO_Output)
    const referenceDate = new Date(qualifier.referenceDate)
    referenceDate.setUTCHours(0, 0, 0, 0)
    referenceDate.setDate(referenceDate.getDate() + 1)

    const adjustedDate = new Date(referenceDate)
    adjustedDate.setDate(adjustedDate.getDate() + (qualifier.intervalEndFromReferenceDate || 0))

    const relationshipString =
        label === 'At any time between' || qualifier.dateRangeOperator === 'Between' ? (
            <>
                {'At any time between'}{' '}
                {new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                }).format(referenceDate)}{' '}
                and{' '}
                {new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                }).format(adjustedDate)}
            </>
        ) : (
            `${label} ${qualifier.intervalStartFromReferenceDate || qualifier.intervalEndFromReferenceDate} ${
                qualifier.intervalUnitFromReferenceDate
            }${
                (qualifier?.intervalStartFromReferenceDate && qualifier?.intervalStartFromReferenceDate === 1) ||
                (qualifier?.intervalEndFromReferenceDate && qualifier?.intervalEndFromReferenceDate === 1)
                    ? ''
                    : 's'
            } ${qualifier.dateRangeOperator.toLowerCase()} ${new Intl.DateTimeFormat('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }).format(new Date(qualifier.referenceDate))}`
        )

    return (
        <NodeContent
            id={`date-qualifier-${parent.blockId}`}
            parentType={qualifier.type}
            onClick={() => {
                onClick && parent?.blockId && onClick(parent?.blockId, qualifierIndex)
            }}
            isQualifier
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>{relationshipString}</div>
            </div>
        </NodeContent>
    )
}
