/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { faSliders, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import MoreTime from '@mui/icons-material/MoreTime'
import { Box, Button, IconButton, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { FrameworkComponentProps, PlatformPermissions } from '@om1/platform-utils'
import { get } from 'lodash'
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import {
    cohortBlocksEditActions,
    CohortDraggingState,
    CohortNodeTargetAddress,
    CohortTreeActionType,
    CriteriaOperation,
    CriteriaType,
    CriterionNode,
    DateQualifier,
    DateWindowFilterDTO,
    FilterDTO,
    FilterType,
    FilterValueQualifier,
    FollowUpLengthQualifier,
    isApiDateWindowFilterDTO,
    isDateQualifier,
    isSpecialtyQualifier,
    PatientAgeQualifier,
    QualifierType,
    SpecialtyQualifier
} from '../../../state'
import { CriteriaTypeIcon } from '../../shared/CriteriaTypeIcon'
import { CriteriaTypeLabel } from '../../shared/CriteriaTypeLabel'
import { CRITERIA_SUPPORT } from '../base/CohortEditComponent'
import { countFilterValuesByField, findQualifierByType, isCuiQualifier, isGemMappedIcd10Qualifier, isIcd10Qualifier } from '../utils/finders'
import { CohortCriterionActionsMenu } from './CohortCriterionActionsMenu'
import { CohortCriterionObservationPeriodSummary } from './CohortCriterionDataTypesSummary'
import { CohortCriterionDragHandle } from './CohortCriterionDragHandle'
import { CohortCriterionFollowUpLengthSummary } from './CohortCriterionFollowUpLengthSummary'
import { CohortCriterionForkDropZone } from './CohortCriterionForkDropZone'
import { CohortCriterionObservationScoreSummary } from './CohortCriterionObservationScoreSummary'
import { CohortCriterionOccurrenceSummary } from './CohortCriterionOccurrenceSummary'
import { CohortCriterionOptionsMenu } from './CohortCriterionOptionsMenu'
import { CohortCriterionPatientAgeSummary } from './CohortCriterionPatientAgeSummary'
import { CohortCriterionRecencySummary } from './CohortCriterionRecencySummary'
import { createCohortCriterionRefSummaryComponent } from './CohortCriterionRefSummary'
import { CohortCriterionRelateDropZone } from './CohortCriterionRelateDropZone'
import { createCohortCriterionSpecialtySummaryComponent } from './CohortCriterionSpecialtySummary'
import { DataType } from './ObservationPeriodDialogComponent'
import { ObservationScoreField } from './ObservationScoreEditDialogComponent'

const CohortCriterionRefSummary = createCohortCriterionRefSummaryComponent()
const CohortCriterionSpecialtySummary = createCohortCriterionSpecialtySummaryComponent()

export type CohortCriterionComponentProps = FrameworkComponentProps<
    {
        permissions: string[]
        observationResultTypeMap: Record<string, string | null>
    },
    typeof cohortBlocksEditActions,
    {
        node: CriterionNode
        relatedNode?: CriterionNode
        parentOperation: CriteriaOperation
        dragState: CohortDraggingState
        relateAddress?: CohortNodeTargetAddress
        forkAddress?: CohortNodeTargetAddress
        readOnly?: boolean
    }
>

export function mapValueToDataType(value: string[]): DataType {
    if (value.length === 2) {
        return t`EHR Linked Medical or Rx Claims Data` as DataType
    }
    switch (value[0]) {
        case 'EHR':
            return t`EHR Data` as DataType
        case 'Medical Claims':
            return t`Medical Claims Data` as DataType
        case 'Pharmacy Claims':
            return t`Pharmacy Claims Data` as DataType
        case 'EHR and Medical Claims':
            return t`EHR Linked Medical Claims Data` as DataType
        case 'EHR and Pharmacy Claims':
            return t`EHR Linked Rx Claims Data` as DataType
        case 'EHR, Medical Claims, and Pharmacy Claims':
            return t`EHR Linked Medical and Rx Claims Data` as DataType
        default:
            return t`Patients with any Data Type` as DataType
    }
}

export function relateIsTargetAllowed(criteriaType: CriteriaType): boolean {
    return criteriaType !== CriteriaType.PatientAttributes && criteriaType !== CriteriaType.ExternalCohort
}

/**
 * The primary representation of a single criterion. For example, Medication or Diagnosis. Contains the criterion type,
 * text summary, as well as interactive elements for editing / deleting / moving.
 */
export const CohortCriterionComponent: React.FunctionComponent<CohortCriterionComponentProps> = ({ state, actions, props }) => {
    const { node, relatedNode, parentOperation, readOnly } = props
    const { dragState, relateAddress, forkAddress } = props
    const { observationResultTypeMap } = state

    let referenceDropZone: ReactNode = null
    let forkDropZone: ReactNode = null
    const isAnyLabs = node.type === CriteriaType.LabTest && node.filters.length > 0 && node.filters[0]?.field === 'has_labs'
    if (!readOnly && !dragState.disable) {
        if (forkAddress) {
            forkDropZone = (
                <Box display='flex' flexDirection='column' flexShrink={0} my={-2}>
                    <CohortCriterionForkDropZone target={forkAddress} parentOperation={parentOperation} dragState={dragState} actions={actions} />
                </Box>
            )
        }

        if (relateAddress && relateIsTargetAllowed(node.type) && !isAnyLabs) {
            referenceDropZone = (
                <Box flexShrink={0}>
                    <CohortCriterionRelateDropZone target={relateAddress} dragState={dragState} actions={actions} />
                </Box>
            )
        }
    }

    const handleCriteriaEdit = () => {
        actions.criteriaDialogTrigger({
            type: node.type,
            action: { type: CohortTreeActionType.Update, target: { nodeId: node.id }, filters: node.filters }
        })
    }

    const handleCriteriaCopy = () => {
        actions.criteriaCopy({ source: { nodeId: node.id } })
    }

    const handleCriteriaDelete = () => {
        actions.criteriaDelete({ nodeId: node.id })
    }

    const handleOccurrenceEdit = () => {
        actions.occurrenceEditDialogTrigger({
            target: { nodeId: node.id },
            relatedTarget: relatedNode ? { nodeId: relatedNode.id } : undefined
        })
    }

    const handleDeleteRecency = () => {
        const qualifiers = [...node.qualifiers.filter((q) => !isDateQualifier(q))]

        let filters: FilterDTO[] | undefined = undefined
        if (node.type === CriteriaType.ObservationPeriod) {
            const filter = node.filters[0]
            const newFilter: FilterDTO = {
                blockId: 0,
                type: FilterType.FilterDTO,
                table: filter.table,
                field: filter.field,
                operator: filter.operator,
                values: filter.values
            }
            filters = [newFilter]
        }

        if (filters) {
            actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers, filters })
        } else {
            actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers })
        }
    }

    const handleRecencyModalOpen = () => {
        actions.recencyEditDialogTrigger({ target: { nodeId: node.id } })
    }

    const handleDeleteSpecialties = () => {
        const qualifiers = [...node.qualifiers.filter((q) => !isSpecialtyQualifier(q))]
        actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers })
    }

    const handleSpecialtiesModalOpen = () => {
        actions.specialtyEditDialogTrigger({ target: { nodeId: node.id } })
    }

    const handlePatientAgeModalOpen = () => {
        actions.patientAgeEditDialogTrigger({ target: { nodeId: node.id } })
    }

    const handleDeletePatientAge = () => {
        const qualifiers = [...node.qualifiers.filter((q) => q.type !== QualifierType.PatientAgeQualifierDTO)]
        actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers })
    }

    const handleFollowUpLengthModalOpen = () => {
        actions.followUpLengthEditDialogTrigger({ target: { nodeId: node.id } })
    }

    const handleDeleteFollowUpLength = () => {
        const qualifiers = [...node.qualifiers.filter((q) => q.type !== QualifierType.FollowUpLengthQualifierDTO)]
        actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers })
    }

    const handleObservationScoreEditModalOpen = () => {
        actions.observationScoreEditDialogTrigger({ target: { nodeId: node.id } })
    }

    const handleDeleteObservationScore = () => {
        const qualifiers = [...node.qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO)]
        actions.criteriaUpdate({ target: { nodeId: node.id }, qualifiers })
    }

    // A criterion is editable if it's supported + not read only + has the correct permission depending on the type
    const editable =
        CRITERIA_SUPPORT[node.type] &&
        !readOnly &&
        !(node.type === CriteriaType.ExternalCohort && !state.permissions.includes(PlatformPermissions.ACCESS_EXTERNAL_COHORTS))
    const hasOccurrence =
        node.type !== CriteriaType.PatientAttributes &&
        node.type !== CriteriaType.ObservationPeriod &&
        node.type !== CriteriaType.ExternalCohort &&
        !isAnyLabs
    const hasRecency =
        findQualifierByType<DateQualifier>(node.qualifiers, QualifierType.DateQualifierDTO) !== undefined ||
        (node.type === CriteriaType.ObservationPeriod && node.filters[0] && isApiDateWindowFilterDTO(node.filters[0] as DateWindowFilterDTO))
    const hasPatientAge = findQualifierByType<PatientAgeQualifier>(node.qualifiers, QualifierType.PatientAgeQualifierDTO) !== undefined
    const hasSpecialty =
        node.type === CriteriaType.EhrNotes &&
        findQualifierByType<SpecialtyQualifier>(node.qualifiers, QualifierType.SpecialtyQualifierDTO) !== undefined
    const hasFollowUpLength =
        node.type === CriteriaType.ObservationPeriod &&
        findQualifierByType<FollowUpLengthQualifier>(node.qualifiers, QualifierType.FollowUpLengthQualifierDTO) !== undefined
    const hasObservationScore =
        node.type === CriteriaType.Observation &&
        findQualifierByType<FilterValueQualifier>(node.qualifiers, QualifierType.FilterQualifierDTO) !== undefined
    const selectedObservationType =
        node.type === CriteriaType.Observation && get(node, ['filters', '0', 'values'], []).length === 1
            ? observationResultTypeMap[get(node, ['filters', '0', 'values', '0'], '')]
            : undefined

    // Observation score should only be available when there's exactly 1 Observation selected
    const countSelectedObservations = node.type === CriteriaType.Observation ? countFilterValuesByField(node, 'observation') : 0

    let dragHandle: ReactNode
    if (!readOnly) {
        dragHandle = (
            <Box width={32}>
                <CohortCriterionDragHandle node={node} actions={actions} />
            </Box>
        )
    }
    if (!readOnly && dragState.disable) {
        dragHandle = <Box width={32} />
    }

    let criteriaTypeLabel: ReactNode = (
        <Box display='flex' gap={0.5}>
            <Box sx={{ marginTop: '6px' }}>
                <CriteriaTypeIcon criteriaType={node.type} />
            </Box>
            <>
                <CriteriaTypeLabel criteriaType={node.type} />
                {isGemMappedIcd10Qualifier(node) && (
                    <Typography fontSize={12}>
                        <Trans>ICD10 + ICD9</Trans>
                    </Typography>
                )}
                {isIcd10Qualifier(node) && (
                    <Typography fontSize={12}>
                        <Trans>ICD10</Trans>
                    </Typography>
                )}
                {isCuiQualifier(node) && (
                    <Typography fontSize={12}>
                        <Trans>OM1 CODING SYSTEM</Trans>
                    </Typography>
                )}
            </>
        </Box>
    )
    if (editable) {
        criteriaTypeLabel = (
            <Button sx={{ padding: 0, paddingInlineEnd: 0.5 }} onClick={handleCriteriaEdit} data-testid='criterion-criteria-type'>
                {criteriaTypeLabel}
            </Button>
        )
    }
    if (node.type === CriteriaType.Diagnosis && node.filters[0]?.table === 'patient_diagnosis') {
        criteriaTypeLabel = (
            <Box display={'flex'} flexDirection={'row'} alignContent={'space-around'} lineHeight={'28px'} fontSize={'14px'} sx={{ opacity: '75%' }}>
                {criteriaTypeLabel}
                {/* <Box paddingLeft={'10px'}>
                    <Trans> (GEM mapping logic applied)</Trans>
                </Box> */}
            </Box>
        )
    }

    const qualifierRows: ReactNode[] = []
    if (hasOccurrence) {
        qualifierRows.push(<CohortCriterionOccurrenceSummary key='occurrence' node={node} readOnly={readOnly} onEditClick={handleOccurrenceEdit} />)
    }
    if (hasRecency && node.type !== CriteriaType.ObservationPeriod) {
        qualifierRows.push(
            <CohortCriterionRecencySummary
                key='recency'
                node={node}
                readOnly={readOnly}
                onDeleteClick={handleDeleteRecency}
                onEditClick={handleRecencyModalOpen}
            />
        )
    }
    if (hasPatientAge) {
        qualifierRows.push(
            <CohortCriterionPatientAgeSummary
                key='age'
                node={node}
                readOnly={readOnly}
                onDeleteClick={handleDeletePatientAge}
                onEditClick={handlePatientAgeModalOpen}
            />
        )
    }
    if (hasSpecialty) {
        qualifierRows.push(
            <CohortCriterionSpecialtySummary
                key='specialties'
                node={node}
                readOnly={readOnly}
                onDeleteClick={handleDeleteSpecialties}
                onEditClick={handleSpecialtiesModalOpen}
            />
        )
    }
    if (node.type === CriteriaType.Observation && hasObservationScore) {
        qualifierRows.push(
            <CohortCriterionObservationScoreSummary
                key='observation-value'
                node={node}
                readOnly={readOnly}
                onDeleteClick={handleDeleteObservationScore}
                onEditClick={handleObservationScoreEditModalOpen}
            />
        )
    }

    if (node.type === CriteriaType.ObservationPeriod) {
        qualifierRows.push(
            <CohortCriterionObservationPeriodSummary
                key='observationPeriod'
                dataType={mapValueToDataType(node.filters[0]?.values)}
                sx={{ marginTop: readOnly ? '0px' : '-15px' }}
            />
        )

        if (hasFollowUpLength) {
            qualifierRows.push(
                <CohortCriterionFollowUpLengthSummary
                    key='observationPeriodFollowUpLength'
                    node={node}
                    readOnly={readOnly}
                    onEditClick={handleFollowUpLengthModalOpen}
                    onDeleteClick={handleDeleteFollowUpLength}
                />
            )
        }

        if (hasRecency) {
            qualifierRows.push(
                <CohortCriterionRecencySummary
                    key='recency'
                    node={node}
                    readOnly={readOnly}
                    onDeleteClick={handleDeleteRecency}
                    onEditClick={handleRecencyModalOpen}
                />
            )
        }
    }

    let qualifierBlock: ReactNode = null
    if (qualifierRows.length > 0) {
        qualifierBlock = (
            <Box display='flex' flexDirection='column' gap={1}>
                {qualifierRows}
            </Box>
        )
    }

    let qualifiersMenu: ReactNode = null
    if (editable && node.type !== CriteriaType.PatientAttributes && !dragState.disable && !isAnyLabs) {
        qualifiersMenu = (
            <CohortCriterionActionsMenu menuId='qualifier-actions-row' menuTestId='test-qualifier-actions-row' disabled={!editable}>
                {node.type !== CriteriaType.ExternalCohort && (
                    <IconButton color='primary' aria-label={t`Add Recency`} onClick={handleRecencyModalOpen} disabled={hasRecency}>
                        <LightTooltip title={t`Add Recency`}>
                            <CalendarMonthOutlinedIcon />
                        </LightTooltip>
                    </IconButton>
                )}
                {node.type !== CriteriaType.ObservationPeriod && node.type !== CriteriaType.ExternalCohort && (
                    <IconButton color='primary' aria-label={t`Add Patient Age`} onClick={handlePatientAgeModalOpen} disabled={hasPatientAge}>
                        <LightTooltip title={t`Add Patient Age`}>
                            <FamilyRestroomOutlinedIcon />
                        </LightTooltip>
                    </IconButton>
                )}
                {node.type === CriteriaType.EhrNotes && (
                    <IconButton color='primary' aria-label={t`Add Provider Specialty`} onClick={handleSpecialtiesModalOpen} disabled={hasSpecialty}>
                        <LightTooltip title={t`Add Provider Specialty`}>
                            <FontAwesomeIcon icon={faUserDoctor} />
                        </LightTooltip>
                    </IconButton>
                )}
                {node.type === CriteriaType.ObservationPeriod && (
                    <IconButton
                        color='primary'
                        aria-label={t`Add observation period length`}
                        onClick={handleFollowUpLengthModalOpen}
                        disabled={hasFollowUpLength}
                    >
                        <LightTooltip title={t`Add observation period length`}>
                            <MoreTime />
                        </LightTooltip>
                    </IconButton>
                )}
                {node.type === CriteriaType.Observation && (
                    <>
                        <IconButton
                            color='primary'
                            aria-label={t`Add observation qualifier`}
                            onClick={handleObservationScoreEditModalOpen}
                            disabled={
                                hasObservationScore ||
                                countSelectedObservations > 1 ||
                                !selectedObservationType ||
                                selectedObservationType === ObservationScoreField.Text
                            }
                        >
                            <LightTooltip title={t`Add observation qualifier`}>
                                <FontAwesomeIcon icon={faSliders} />
                            </LightTooltip>
                        </IconButton>
                    </>
                )}
            </CohortCriterionActionsMenu>
        )
    }

    return (
        <Box display='flex' py={2} boxShadow={1} bgcolor='white' data-testid='criterion-node' position='relative'>
            {dragHandle}

            <Box display='flex' flexDirection='column' gap={1} flex={1} minWidth={0} ml={readOnly ? 2 : 0.5} mr={2}>
                <Box display='flex' gap={1} minWidth={0}>
                    <Box display='flex' flexDirection='column' flex={1} minWidth={0}>
                        <Box>{criteriaTypeLabel}</Box>

                        <CohortCriterionRefSummary filters={node.filters} type={node.type} />
                    </Box>

                    {referenceDropZone}

                    <Box display='flex' flexShrink={0} mr={-0.5}>
                        <CohortCriterionOptionsMenu
                            node={node}
                            editable={editable}
                            dragStateEnabled={!dragState.disable}
                            handleCriteriaEdit={handleCriteriaEdit}
                            handleCriteriaCopy={handleCriteriaCopy}
                            handleCriteriaDelete={handleCriteriaDelete}
                        />
                    </Box>
                </Box>

                {qualifierBlock}
                {qualifiersMenu}
            </Box>

            {forkDropZone}
        </Box>
    )
}

interface DropDownMenuProps {
    toggleId: string
    toggleTestId: string
    toggleIcon: ReactNode
    toggleLabel: string
    menuId: string
    menuTestId: string
    disabled?: boolean
}

export const DropDownMenu: React.FunctionComponent<PropsWithChildren<DropDownMenuProps>> = (props) => {
    const { toggleId, toggleTestId, toggleIcon, toggleLabel, menuId, menuTestId, disabled, children } = props
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null)
    const menuOpen = Boolean(menuAnchor)
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget)
    const handleMenuClose = () => setMenuAnchor(null)

    return (
        <Box>
            <IconButton
                id={toggleId}
                onClick={handleMenuOpen}
                aria-label={toggleLabel}
                aria-controls={menuOpen ? menuId : undefined}
                aria-haspopup='true'
                aria-expanded={menuOpen ? 'true' : undefined}
                color='primary'
                disabled={disabled}
                data-testid={toggleTestId}
                sx={{ padding: 1 }}
            >
                {toggleIcon}
            </IconButton>
            <Menu
                id={props.menuId}
                open={menuOpen}
                anchorEl={menuAnchor}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{ 'aria-labelledby': toggleId }}
                data-testid={menuTestId}
            >
                {children}
            </Menu>
        </Box>
    )
}
