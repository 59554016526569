import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortCommonActions } from '../../state'
import { GenerateReportComponent } from './GenerateReportComponent'

export function createGenerateReportComponent<TState extends { cohort: CohortState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(GenerateReportComponent),
        (state: TState) => ({
            cohortSummaryReportLoading: state.cohort.common.ui.cohortSummaryReportLoading,
            cohorts: state.cohort.list.cohorts,
            permissions: state.auth0.permissions ?? []
        }),
        { ...cohortCommonActions }
    )
}

export type GenerateReport = ReturnType<typeof createGenerateReportComponent>
