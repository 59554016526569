import {
    CountDistinctQualifierDTO_Output,
    DateAwareFilterDTO_Output,
    DateQualifierDTO_Output,
    FilterDTO_Output,
    FilterQualifierDTO_Output,
    FollowUpLengthQualifierDTO_Output,
    PatientAgeQualifierDTO_Output,
    SpecialtyQualifierDTO_Output
} from '@om1/falcon-api'
import { FC } from 'react'

import { CountDistinctQualifier } from './CountDistinctQualifier'
import { DateQualifier } from './DateQualifier'
import { FilterQualifier } from './FilterQualifier'
import { FollowUpLengthQualifier } from './FollowUpLengthQualifier'
import { PatientAgeQualifier } from './PatientAgeQualifier'
import { SpecialtyQualifier } from './SpecialtyQualifier'

export const Qualifier: FC<{
    qualifier:
        | DateQualifierDTO_Output
        | PatientAgeQualifierDTO_Output
        | FollowUpLengthQualifierDTO_Output
        | SpecialtyQualifierDTO_Output
        | DateAwareFilterDTO_Output
        | CountDistinctQualifierDTO_Output
        | FilterQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, depth, parent, onClick, table, disableClick = false, qualifierIndex = -1 }) => {
    switch (qualifier.type) {
        case 'DateQualifierDTO':
            return (
                <DateQualifier
                    qualifier={qualifier as DateQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        case 'PatientAgeQualifierDTO':
            return (
                <PatientAgeQualifier
                    qualifier={qualifier as PatientAgeQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        case 'FollowUpLengthQualifierDTO':
            return (
                <FollowUpLengthQualifier
                    qualifier={qualifier as FollowUpLengthQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        case 'SpecialtyQualifierDTO':
            return (
                <SpecialtyQualifier
                    qualifier={qualifier as SpecialtyQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        case 'CountDistinctQualifierDTO':
            return (
                <CountDistinctQualifier
                    qualifier={qualifier as CountDistinctQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        case 'FilterQualifierDTO':
            return (
                <FilterQualifier
                    qualifier={qualifier as FilterQualifierDTO_Output}
                    depth={depth}
                    parent={parent}
                    onClick={onClick}
                    table={table}
                    disableClick={disableClick}
                    qualifierIndex={qualifierIndex}
                />
            )
        default:
            return <>{JSON.stringify({ ...qualifier, qualifierIndex: qualifierIndex })}</>
    }
}
