/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CSSProperties, FC, MouseEventHandler, MouseEvent as ReactMouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

interface SplitProps {
    sizes?: number[]
    minSize?: number[]
    children: ReactNode[]
    style?: CSSProperties
}

export const Split: FC<SplitProps> = ({ sizes = [50, 50], minSize = [0, 0], children, style }) => {
    const [splitSizes, setSplitSizes] = useState(sizes)
    const containerRef = useRef<HTMLDivElement>(null)
    const isDragging = useRef(false)
    const startX = useRef(0)
    const startSizes = useRef(splitSizes)

    const handleMouseDown = (e: ReactMouseEvent) => {
        isDragging.current = true
        startX.current = e.clientX
        startSizes.current = splitSizes
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging.current || !containerRef.current) return

            const delta = e.clientX - startX.current
            const containerWidth = containerRef.current.offsetWidth
            const deltaPercentage = (delta / containerWidth) * 100

            const newLeftSize = Math.max(
                (minSize[0] / containerWidth) * 100,
                Math.min(100 - (minSize[1] / containerWidth) * 100, startSizes.current[0] + deltaPercentage)
            )

            setSplitSizes([newLeftSize, 100 - newLeftSize])
        },
        [minSize]
    )

    const handleMouseUp = useCallback(() => {
        isDragging.current = false
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }, [handleMouseMove])

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [handleMouseMove, handleMouseUp])

    return (
        <div ref={containerRef} style={{ display: 'flex', width: '100%', height: '80dvh', ...style }}>
            <div style={{ width: `${splitSizes[0]}%`, minWidth: minSize[0] }}>{children[0]}</div>
            <div
                style={{
                    width: '4px',
                    backgroundColor: '#e0e0e0',
                    cursor: 'col-resize',
                    userSelect: 'none'
                }}
                onMouseDown={handleMouseDown as MouseEventHandler<HTMLDivElement>}
            />
            <div style={{ width: `${splitSizes[1]}%`, minWidth: minSize[1] }}>{children[1]}</div>
        </div>
    )
}
