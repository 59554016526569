import { ExplorerAssistantConversationService, PaginatedDTO_AssistantConversationDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'

export function createGetExplorerAssistantConversationsSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.getConversations>) {
        yield put(explorerAssistantConversationActions.setConversationsLoading(true))
        try {
            if (!action.payload.query.referenceCohortId) {
                throw new Error('Reference Cohort ID is required')
            }
            const paginatedDTOAssistantConversationDTO: PaginatedDTO_AssistantConversationDTO_ = yield call(
                ExplorerAssistantConversationService.indexExplorerAssistantConversationGet,
                {
                    referenceCohortId: action.payload.query.referenceCohortId,
                    page: action.payload.query.page || 1,
                    limit: action.payload.query.limit || 10
                }
            )
            yield put(
                explorerAssistantConversationActions.getConversationsSuccess({
                    data: paginatedDTOAssistantConversationDTO.data,
                    meta: paginatedDTOAssistantConversationDTO.meta
                })
            )
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(explorerAssistantConversationActions.setConversationsLoading(false))
        }
    }
}

export type GetExplorerAssistantConversationsSaga = ReturnType<typeof createGetExplorerAssistantConversationsSaga>
