import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { reportsActions } from '../../state'
import { CohortReportsComponent } from './CohortReportsComponent'

export function createCohortReports<TState extends { auth0: Auth0State }>() {
    return connectRedux(withRouter(CohortReportsComponent), (state: TState) => ({}), reportsActions)
}

export type CohortReports = ReturnType<typeof createCohortReports>
