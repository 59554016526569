/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FilterQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterQualifierDTO_Output'
import { FC } from 'react'
import { NodeContent } from '../nodes/NodeContent'

export const tableMap: Record<string, string> = {
    patient_diagnosis: 'diagnosis',
    patient_lab: 'lab',
    patient_medication: 'medication',
    patient_note: 'note',
    patient_external_cohort: 'external cohort',
    observation_period: 'observation period',
    patient_observation: 'observation',
    patient_procedure: 'procedure'
}

export const FilterQualifier: FC<{
    qualifier: FilterQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, parent, onClick, disableClick = false, qualifierIndex = -1 }) => {
    const { operator, values } = qualifier
    const operatorString = () => {
        switch (operator) {
            case 'in':
                return 'is in'
            case 'notIn':
                return 'is not in'
            case 'equals':
                return 'is'
            case 'notEquals':
                return 'is not'
            case 'gt':
                return 'is greater than'
            case 'gte':
                return 'is greater than or equal to'
            case 'lt':
                return 'is less than'
            case 'lte':
                return 'is less than or equal to'
            case 'between':
                return 'is between'
            case 'notBetween':
                return 'is not between'
            default:
                return operator
        }
    }
    const valueString = `Observation score ${operatorString()} ${values.map((value) => value).join(', ')}`
    return (
        <NodeContent
            id={`filter-qualifier-${parent.blockId}`}
            parentType={qualifier.type}
            onClick={() => {
                onClick && parent?.blockId && onClick(parent?.blockId, qualifierIndex)
            }}
            isQualifier
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
        >
            <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>{valueString}</div>
        </NodeContent>
    )
}
