import {
    AndDTO_Output,
    DateAwareFilterDTO_Output,
    DateWindowFilterDTO_Output,
    ExceptDTO_Output,
    FilterDTO_Output,
    OrDTO_Output,
    QueryDTO_Output,
    RelativeDateFilterDTO_Output,
    RelativeFollowUpFilterDTO_Output
} from '@om1/falcon-api'
import { FC } from 'react'
import { DateAwareFilter } from './filters/DateAwareFilter'
import { DateWindowFilter } from './filters/DateWindowFilter'
import { Filter } from './filters/Filter'
import { RelativeDateFilter } from './filters/RelativeDateFilter'
import { RelativeFollowUpFilter } from './filters/RelativeFollowUpFilter'
import { And } from './nodes/And'
import { Except } from './nodes/Except'
import { Or } from './nodes/Or'

export type FilterTypes = NonNullable<QueryDTO_Output['filters']>[number]

export const QueryBlock: FC<{
    block: { node: FilterTypes; blockId?: number | null }
    depth?: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number | null
}> = ({ block, depth = 0, isSubject = false, isReference = false, onClick, selectedBlockId }) => {
    const { node } = block

    switch (node.type) {
        case 'ExceptDTO':
            return <Except node={node as ExceptDTO_Output} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId || undefined} />
        case 'AndDTO':
            return <And node={node as AndDTO_Output} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId || undefined} />
        case 'OrDTO':
            return <Or node={node as OrDTO_Output} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId || undefined} />
        case 'FilterDTO':
            return (
                <Filter
                    node={node as FilterDTO_Output}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId || undefined}
                />
            )
        case 'DateAwareFilterDTO':
            return (
                <DateAwareFilter
                    node={node as DateAwareFilterDTO_Output}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId || undefined}
                />
            )
        case 'RelativeDateFilterDTO':
            return (
                <RelativeDateFilter
                    node={node as RelativeDateFilterDTO_Output}
                    depth={depth}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId || undefined}
                />
            )
        case 'DateWindowFilterDTO':
            return (
                <DateWindowFilter
                    node={node as DateWindowFilterDTO_Output}
                    depth={depth}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId || undefined}
                />
            )
        case 'RelativeFollowUpFilterDTO':
            return (
                <RelativeFollowUpFilter
                    node={node as RelativeFollowUpFilterDTO_Output}
                    depth={depth}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId || undefined}
                />
            )
        default:
            if ((node as any).table) {
                return (
                    <Filter
                        node={node as FilterDTO_Output}
                        depth={depth}
                        isSubject={isSubject}
                        isReference={isReference}
                        onClick={onClick}
                        selectedBlockId={selectedBlockId || undefined}
                    />
                )
            } else {
                return (
                    <pre
                        onClick={() => onClick?.((node as unknown as any).blockId)}
                        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', border: '1px dotted gray', background: 'white', padding: '1rem' }}
                    >
                        {JSON.stringify(node, null, 2)}
                    </pre>
                )
            }
    }
}
