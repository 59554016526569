/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ExceptDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { QueryBlock } from '../QueryBlock'
import { QueryNode } from './QueryNode'

export const Except: FC<{
    node: ExceptDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, onClick, selectedBlockId }) => {
    const disabled = node.disabled
    if (depth > 0) {
        return (
            <QueryNode
                depth={depth}
                title={`Except ${node.blockId}`}
                blockId={node.blockId}
                onClick={(blockId: number) => onClick && onClick(blockId)}
                selectedBlockId={selectedBlockId}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                    {node.except.map((except, index) => (
                        <div key={Number(except.blockId) + index}>
                            <QueryBlock
                                block={{ blockId: except.blockId, node: { ...except, disabled: except.disabled || disabled } }}
                                depth={depth + 1}
                                onClick={onClick}
                                selectedBlockId={selectedBlockId}
                            />
                        </div>
                    ))}
                </div>
            </QueryNode>
        )
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                {node.except.map((except, index) => (
                    <div key={Number(except.blockId) + index}>
                        <QueryBlock
                            block={{ blockId: except.blockId, node: except }}
                            depth={depth + 1}
                            onClick={onClick}
                            selectedBlockId={selectedBlockId}
                        />
                    </div>
                ))}
            </div>
        )
    }
}
