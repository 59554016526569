/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { RelativeFollowUpFilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { QueryNode } from '../nodes/QueryNode'

export const RelativeFollowUpFilter: FC<{
    node: RelativeFollowUpFilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, onClick, selectedBlockId }) => {
    return (
        <QueryNode
            depth={depth}
            title={`RelativeFollowUpFilter ${node.blockId}`}
            blockId={node.blockId}
            onClick={onClick}
            selectedBlockId={selectedBlockId}
        >
            RelativeFollowUpFilter [{node.blockId}]: {JSON.stringify(node)}
        </QueryNode>
    )
}
