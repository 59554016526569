/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { PaginatedDictDTO_str_Any_ } from '@om1/falcon-api/codegen/client/models/PaginatedDictDTO_str_Any_'
import { RefTable } from '@om1/falcon-api/codegen/client/models/RefTable'
import { SpecialtyQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/SpecialtyQualifierDTO_Output'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import { FC, useEffect, useState } from 'react'
import { NodeContent } from '../nodes/NodeContent'
import { fetchData } from '../queryUtils'
import { tableMap } from './CountDistinctQualifier'

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const SpecialtyQualifier: FC<{
    qualifier: SpecialtyQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, parent, onClick, table, disableClick = false, qualifierIndex = -1 }) => {
    const [paginatedDict, setPaginatedDict] = useState<PaginatedDictDTO_str_Any_ | null>(null)
    useEffect(() => {
        const fetchDataAsync = async () => {
            const data = await fetchData(
                'explorerCohorts',
                { refTableName: 'ref_specialty' as RefTable, page: 1, limit: 100, query: null, filters: qualifier.values },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setPaginatedDict(data)
        }

        fetchDataAsync()
    }, [qualifier, table])

    return (
        <NodeContent
            id={`specialty-qualifier-${parent.blockId}`}
            parentType={qualifier.type}
            onClick={() => {
                onClick && parent?.blockId && onClick(parent?.blockId, qualifierIndex)
            }}
            isQualifier
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
        >
            <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>
                {`${capitalizeFirstLetter((table && tableMap[table]) || table || 'default')}s from ${qualifier.values
                    .map((value) => {
                        const specialty = paginatedDict?.data.find((item) => item.specialty_concept_id === value)?.specialty
                        return specialty || value // Fallback to the value if no specialty is found
                    })
                    .join(', ')}`}
            </div>
        </NodeContent>
    )
}
