/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { AndDTO_Output, FilterDTO_Output, OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { PatientAndGroup } from '../filters/PatientAndGroup'
import { QueryBlock } from '../QueryBlock'
import { QueryNode } from './QueryNode'

export const isFilterDTO = (node: any) => {
    return node.type === 'FilterDTO'
}

export const isOrDTO = (node: any) => {
    return node.type === 'OrDTO'
}

export const isPatientAndGroup = (node: AndDTO_Output) => {
    return (
        node.and.length === 3 &&
        isFilterDTO(node.and[0]) &&
        isFilterDTO(node.and[1]) &&
        isOrDTO(node.and[2]) &&
        (node.and[2] as OrDTO_Output).or.length === 3 &&
        (node.and[2] as OrDTO_Output).or[0].type === 'FilterDTO' &&
        (node.and[2] as OrDTO_Output).or[1].type === 'FilterDTO' &&
        (node.and[2] as OrDTO_Output).or[2].type === 'FilterDTO' &&
        ((node.and[2] as OrDTO_Output).or[0] as FilterDTO_Output).table === 'patient' &&
        ((node.and[2] as OrDTO_Output).or[1] as FilterDTO_Output).table === 'patient' &&
        ((node.and[2] as OrDTO_Output).or[2] as FilterDTO_Output).table === 'patient'
    )
}

export const And: FC<{
    node: AndDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, onClick, selectedBlockId }) => {
    if (isPatientAndGroup(node)) {
        return <PatientAndGroup filter={node} depth={depth + 1} onClick={onClick} selectedBlockId={selectedBlockId} />
    } else {
        const disabled = node.disabled
        return (
            <QueryNode depth={depth} blockId={node.blockId} onClick={onClick} selectedBlockId={selectedBlockId} disabled={Boolean(disabled)} and>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                    {node.and.map((and, index, array) => {
                        return (
                            <div key={Number(and.blockId) + index}>
                                <QueryBlock
                                    block={{ blockId: and.blockId, node: { ...and, disabled: and.disabled || disabled } }}
                                    depth={depth + 1}
                                    onClick={onClick}
                                    selectedBlockId={selectedBlockId}
                                />
                                {index < array.length - 1 && (
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: '0'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                height: '50px',
                                                width: '1px',
                                                backgroundColor: '#A2C6EB', // bg-orange-200
                                                zIndex: 0,
                                                marginTop: '5px'
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: 'sticky',
                                                zIndex: 10,
                                                padding: '0.5rem 1.5rem',
                                                fontSize: '1rem',
                                                border: '1px solid',
                                                marginTop: '0.25rem',
                                                backgroundColor: '#F5F5F5', // bg-gray-50 or bg-orange-50
                                                color: 'black', // text-gray-400 or text-orange-700
                                                borderColor: '#F5F5F5'
                                            }}
                                        >
                                            AND
                                        </span>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </QueryNode>
        )
    }
}
