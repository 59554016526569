import { DateAwareFilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { GenericDateAwareFilter } from './GenericDateAwareFilter'
import { ObservationPeriodFilter } from './ObservationPeriodFilter'
import { PatientDiagnosisFilter } from './PatientDiagnosisFilter'
import { PatientLabFilter } from './PatientLabFilter'
import { PatientMedicationFilter } from './PatientMedicationFilter'
import { PatientNoteFilter } from './PatientNoteFilter'
import { PatientObservationFilter } from './PatientObservationFilter'
import { PatientProcedureFilter } from './PatientProcedureFilter'

export const DateAwareFilter: FC<{
    node: DateAwareFilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    switch (node.table) {
        case 'patient_note':
            return (
                <PatientNoteFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_procedure':
            return (
                <PatientProcedureFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_medication':
            return (
                <PatientMedicationFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_diagnosis':
            return (
                <PatientDiagnosisFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'observation_period':
            return (
                <ObservationPeriodFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_lab':
            return (
                <PatientLabFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_observation':
            return (
                <PatientObservationFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        default:
            return (
                <GenericDateAwareFilter
                    node={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
    }
}
