/* eslint-disable */
import { useDroppable } from '@dnd-kit/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { FC, ReactNode, useState } from 'react'

// Base card content wrapper
export const NodeContent: FC<{
    children: ReactNode
    id: string
    parentType: 'DateAwareFilterDTO' | 'FilterDTO' | 'RelativeDateFilterDTO' | 'AndDTO' | 'OrDTO' | 'DateQualifierDTO' | string | undefined
    disabled?: boolean
    onDelete?: () => void
    onEdit?: () => void
    disableAndDrop?: boolean
    allowRelateDrop?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: string
    isQualifier?: boolean
    disableClick?: boolean
    qualifierIndex?: number
}> = ({
    children,
    disabled,
    onDelete,
    onEdit,
    id,
    disableAndDrop = false,
    allowRelateDrop = false,
    parentType,
    onClick,
    selectedBlockId,
    isQualifier = false,
    disableClick = false,
    qualifierIndex = -1
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { active, setNodeRef } = useDroppable({ id, disabled: Boolean(disabled) })
    const { setNodeRef: setAndRef } = useDroppable({ id: `and-${id}`, disabled: Boolean(disabled) })
    const { setNodeRef: setRelateRef } = useDroppable({ id: `relate-${id}`, disabled: Boolean(disabled) })
    const isRelativeDateFilter = parentType === 'RelativeDateFilterDTO'
    const renderAndDrop = active && !disabled && !disableAndDrop && !isQualifier
    const renderRelateDrop = active && !disabled && allowRelateDrop && !isQualifier
    const clickable = !disabled && !disableClick
    const isSelected = Number(id) === Number(selectedBlockId) && !(isNaN(Number(id)) && isNaN(Number(selectedBlockId)))

    return (
        <>
            <style>
                {`
                    /* eslint-disable-next-line string-to-lingui/text-restrictions */
                    @property --angle {
                        syntax: '<angle>';
                        initial-value: 0deg;
                        inherits: false;
                    }

                    @keyframes rotate {
                        to {
                            --angle: 360deg;
                        }
                    }

                    .animated-border {
                        position: relative;
                        overflow: hidden;
                    }

                    .animated-border::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: inherit;
                        padding: 2px;
                        background: linear-gradient(var(--angle), #0000ff, #0000b3);
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        mask-composite: exclude;
                        animation: rotate 3s linear infinite;
                        pointer-events: none;
                    }
                `}
            </style>
            <div style={{ padding: isQualifier ? '0px' : '20px', backgroundColor: isQualifier ? 'transparent' : '#F5F5F5', width: '100%' }}>
                <div
                    style={{
                        zIndex: 50,
                        width: '100%',
                        height: isQualifier ? '100%' : '',
                        marginTop: isQualifier ? '0.25rem' : '0rem',
                        overflow: 'hidden',
                        position: 'relative',
                        borderWidth: isSelected ? '5px' : '',
                        padding: isQualifier ? '0.25rem 0.25rem 0.25rem 0.25rem' : '0.25rem 1rem 1rem 1rem',
                        opacity: disabled ? 0.25 : 1,
                        backgroundColor: isQualifier ? '#EEEEEE' : '#FFFFFF',
                        boxShadow: isQualifier
                            ? ''
                            : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                        // borderColor: isRelativeDateFilter ? '#e9d8fd' : isSelected ? '3px solid' : 'transparent',
                        // border: isSelected
                        //     ? isRelativeDateFilter
                        //         ? '1px solid gray'
                        //         : '1px solid blue'
                        //     : isQualifier
                        //     ? '1px solid transparent'
                        //     : isRelativeDateFilter
                        //     ? '1px dotted gray'
                        //     : '1px dotted blue',
                        cursor: clickable ? 'pointer' : 'default'
                    }}
                    ref={setNodeRef}
                    onClick={(event) => {
                        if (clickable && onClick && id) {
                            event.stopPropagation()
                            onClick(Number(id), isQualifier ? qualifierIndex : undefined)
                        }
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ flex: 1, transition: 'all 0.3s', paddingRight: renderAndDrop ? '3rem' : undefined }}>
                            {!isQualifier && id && id !== 'undefined' && (
                                <div
                                    style={{
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        fontSize: '0.75rem',
                                        color: isSelected ? (isRelativeDateFilter ? 'purple' : 'blue') : 'gray',
                                        opacity: isSelected ? 1 : 0.6
                                    }}
                                >
                                    {isRelativeDateFilter ? '\u00A0' : ''}
                                </div>
                            )}
                            {children}
                            {(onEdit || onDelete) && (
                                <div style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)' }}>
                                    <IconButton
                                        aria-controls='card-menu'
                                        aria-haspopup='true'
                                        onClick={(event) => setAnchorEl(event.currentTarget)}
                                        size='small'
                                    >
                                        <MoreHorizIcon fontSize='small' />
                                    </IconButton>

                                    <Menu id='card-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                        {onEdit && (
                                            <MenuItem
                                                onClick={() => {
                                                    onEdit()
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Edit
                                            </MenuItem>
                                        )}
                                        {onDelete && (
                                            <MenuItem
                                                onClick={() => {
                                                    onDelete()
                                                    setAnchorEl(null)
                                                }}
                                                sx={{ color: 'error.main' }}
                                            >
                                                Delete
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            )}
                        </div>
                        {renderRelateDrop && (
                            <div
                                ref={setRelateRef}
                                style={{
                                    width: '8rem',
                                    height: '8rem',
                                    display: 'grid',
                                    placeItems: 'center',
                                    marginRight: '1.5rem',
                                    border: '2px solid',
                                    borderRadius: '0.5rem',
                                    cursor: 'pointer',
                                    transition: 'all 0.15s',
                                    borderColor: active ? '#9f7aea' : 'transparent',
                                    backgroundColor: active ? '#faf5ff' : 'transparent',
                                    visibility: active ? 'visible' : 'hidden'
                                }}
                            >
                                <span style={{ fontSize: '1rem', fontWeight: '500' }}>Relate</span>
                            </div>
                        )}
                        {renderAndDrop && (
                            <div
                                ref={setAndRef}
                                style={{
                                    width: '8rem',
                                    height: '8rem',
                                    display: 'grid',
                                    placeItems: 'center',
                                    border: '2px solid',
                                    borderRadius: '0.5rem',
                                    cursor: 'pointer',
                                    transition: 'all 0.15s',
                                    borderColor: active ? '#4299e1' : 'transparent',
                                    backgroundColor: active ? '#ebf8ff' : 'transparent',
                                    visibility: active ? 'visible' : 'hidden'
                                }}
                            >
                                <span style={{ fontSize: '1rem', fontWeight: '500' }}>And</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
