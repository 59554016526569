/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC } from 'react'
import { NodeContent } from '../nodes/NodeContent'
import { QueryBlock } from '../QueryBlock'

export const determineLabel = (
    node: RelativeDateFilterDTO_Output
): 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between' | undefined => {
    const { intervalStartFromReferenceDate, intervalEndFromReferenceDate, intervalIsInclusive } = node
    if (node.dateRangeOperator === 'Between') {
        return 'At any time between'
    } else if (intervalStartFromReferenceDate === 0 && intervalEndFromReferenceDate !== null && intervalIsInclusive) {
        return 'At most'
    } else if (intervalStartFromReferenceDate !== null && intervalEndFromReferenceDate === null && intervalIsInclusive) {
        return 'At least'
    } else if (intervalStartFromReferenceDate !== null && intervalEndFromReferenceDate !== null && intervalIsInclusive) {
        return 'Exactly'
    } else if (intervalStartFromReferenceDate === 0 && intervalEndFromReferenceDate !== null && !intervalIsInclusive) {
        return 'Less than'
    } else if (intervalStartFromReferenceDate !== null && intervalEndFromReferenceDate === null && !intervalIsInclusive) {
        return 'More than'
    } else {
        return undefined
    }
}

export const getNodeFromLabel = (
    label: 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between',
    amount: number
): Partial<RelativeDateFilterDTO_Output> => {
    switch (label) {
        case 'At most':
            return {
                intervalStartFromReferenceDate: 0,
                intervalEndFromReferenceDate: amount,
                intervalIsInclusive: true,
                dateRangeOperator: 'After'
            }
        case 'At least':
            return {
                intervalStartFromReferenceDate: amount,
                intervalEndFromReferenceDate: null,
                intervalIsInclusive: true,
                dateRangeOperator: 'After'
            }
        case 'Exactly':
            return {
                intervalStartFromReferenceDate: amount,
                intervalEndFromReferenceDate: amount,
                intervalIsInclusive: true,
                dateRangeOperator: 'After'
            }
        case 'Less than':
            return {
                intervalStartFromReferenceDate: 0,
                intervalEndFromReferenceDate: amount,
                intervalIsInclusive: false,
                dateRangeOperator: 'After'
            }
        case 'More than':
            return {
                intervalStartFromReferenceDate: amount,
                intervalEndFromReferenceDate: null,
                intervalIsInclusive: false,
                dateRangeOperator: 'After'
            }
        case 'At any time between':
            return {
                dateRangeOperator: 'Between'
            }
        default:
            return {}
    }
}

export const RelativeDateFilter: FC<{
    node: RelativeDateFilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, onClick, selectedBlockId }) => {
    const disabled = node.disabled
    const { intervalStartFromReferenceDate, intervalUnitFromReferenceDate, dateRangeOperator } = node
    const label = determineLabel(node)
    const relationshipString = `${label} ${intervalStartFromReferenceDate !== undefined ? intervalStartFromReferenceDate : '' ?? ''} ${
        intervalUnitFromReferenceDate !== undefined ? intervalUnitFromReferenceDate : ''
    }${intervalStartFromReferenceDate && intervalStartFromReferenceDate > 0 ? 's' : ''} ${
        dateRangeOperator === 'BeforeOrAfter' ? 'before or after' : dateRangeOperator.toLowerCase()
    }`.trim()

    return (
        <NodeContent
            disabled={Boolean(disabled)}
            id={String(node.blockId)}
            parentType={node.type}
            onClick={onClick}
            selectedBlockId={selectedBlockId?.toString()}
        >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <QueryBlock
                        block={{ blockId: node.subjectFilter.blockId, node: node.subjectFilter }}
                        depth={depth + 1}
                        isSubject
                        onClick={onClick}
                        selectedBlockId={selectedBlockId}
                    />
                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            height: '50px',
                            width: '1px',
                            backgroundColor: '#A2C6EB', // bg-orange-200
                            zIndex: 0,
                            marginTop: '0px'
                        }}
                    />
                    <span
                        style={{
                            position: 'sticky',
                            zIndex: 10,
                            padding: '0.5rem 1.5rem',
                            fontSize: '1rem',
                            border: '1px solid',
                            marginTop: '0.25rem',
                            marginBottom: '0.25rem',
                            backgroundColor: 'white', // bg-gray-50 or bg-orange-50
                            color: 'black', // text-gray-400 or text-orange-700
                            borderColor: 'white'
                        }}
                    >
                        {relationshipString}
                    </span>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <QueryBlock
                        block={{ blockId: node.referenceFilter.blockId, node: node.referenceFilter }}
                        depth={depth + 1}
                        isReference
                        onClick={onClick}
                        selectedBlockId={selectedBlockId}
                    />
                </div>
            </div>
        </NodeContent>
    )
}
