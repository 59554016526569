/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { isPatientOrGroup, PatientOrGroup } from '../filters/PatientOrGroup'
import { QueryBlock } from '../QueryBlock'
import { QueryNode } from './QueryNode'

export const Or: FC<{
    node: OrDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, onClick, selectedBlockId }) => {
    if (isPatientOrGroup(node)) {
        return <PatientOrGroup node={node} depth={depth + 1} onClick={onClick} selectedBlockId={selectedBlockId} />
    } else {
        const disabled = node.disabled
        return (
            <QueryNode
                depth={depth}
                title={``}
                blockId={node.blockId}
                onClick={onClick}
                selectedBlockId={selectedBlockId}
                disabled={Boolean(disabled)}
                or
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                    {node.or.map((or, index, array) => {
                        return (
                            <div key={Number(or.blockId) + index}>
                                <QueryBlock
                                    block={{ blockId: or.blockId, node: { ...or, disabled: or.disabled || disabled } }}
                                    depth={depth + 1}
                                    onClick={onClick}
                                    selectedBlockId={selectedBlockId}
                                />
                                {index < array.length - 1 && (
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: '0'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                height: '50px',
                                                width: '1px',
                                                backgroundColor: '#A2C6EB', // bg-orange-200
                                                zIndex: 0,
                                                marginTop: '5px'
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: 'sticky',
                                                zIndex: 10,
                                                padding: '0.5rem 1.5rem',
                                                fontSize: '1rem',
                                                border: '1px solid',
                                                marginTop: '0.25rem',
                                                backgroundColor: 'white', // bg-gray-50 or bg-orange-50
                                                color: 'black', // text-gray-400 or text-orange-700
                                                borderColor: 'white'
                                            }}
                                        >
                                            OR
                                        </span>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </QueryNode>
        )
    }
}
