import { DataDeliveryRegistryTypesService, RegistryTypeDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { put } from 'redux-saga/effects'
import { fetchDataWithCacheGenerator } from '../../../../apps/platform/src/query-utils'
import { dataDeliveryActions } from '../state/data-delivery'

export function createGetDataDeliveryRegistryTypesSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.getDataDeliveryRegistryTypes>) {
        try {
            yield put(dataDeliveryActions.dataDeliveryRegistryTypesLoading({ loading: true }))
            const response: RegistryTypeDTO[] = yield fetchDataWithCacheGenerator(
                {},
                DataDeliveryRegistryTypesService.indexDataDeliveryRegistryTypesGet
            )
            yield put(dataDeliveryActions.setDataDeliveryRegistryTypes({ registryTypes: response }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.dataDeliveryRegistryTypesLoading({ loading: false }))
        }
    }
}

export type GetDataDeliveryRegistryTypesSaga = ReturnType<typeof createGetDataDeliveryRegistryTypesSaga>
