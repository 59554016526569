import { t } from '@lingui/macro'
import { Box, SxProps, TextField, Theme, Tooltip, Typography } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

interface EditableParagraphProps {
    editable?: boolean
    text?: string
    onSave?: (newText: string) => void
    sx?: SxProps<Theme>
    tooltip?: string
}

const EditableParagraph = (props: EditableParagraphProps) => {
    const disabled: boolean = props.editable === false
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [text, setText] = useState<string>(props.text || '')
    const originalText = useRef<string | undefined>(props.text) // Store the original text for comparison
    const defaultText = t`Click to add a description`

    // Invisible span ref
    const measureRef = useRef<HTMLSpanElement | null>(null)
    const [width, setWidth] = useState<string>('auto')

    // Update the originalText ref and text if the props.text changes
    useEffect(() => {
        originalText.current = props.text ?? ''
        setText(props.text ?? '')
    }, [props.text])

    // Update width when text changes
    useEffect(() => {
        if (measureRef.current) {
            setWidth(`${measureRef.current.offsetWidth + 20}px`)
        }
    }, [text])

    const handleEdit = () => {
        if (!disabled) {
            setIsEditing(true)
        }
    }

    const handleSave = () => {
        props.onSave && props.onSave(text)
        setIsEditing(false)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave()
        }
    }

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value)
    }

    const handleBlur = () => {
        handleSave()
    }

    return (
        <Box
            position='relative'
            onClick={handleEdit}
            p={2}
            sx={{
                ...props.sx,
                cursor: !disabled ? 'pointer' : 'default',
                '&:hover': {
                    backgroundColor: !disabled ? 'rgba(0, 45, 114, 0.04)' : 'transparent'
                },
                backgroundColor: isEditing ? 'rgba(0, 45, 114, 0.04)' : 'transparent',
                border: '1px solid transparent',
                borderRadius: '8px',
                marginTop: '16px',
                marginLeft: '16px',
                paddingTop: '-10px'
            }}
        >
            <span ref={measureRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'pre' }}>
                {text || defaultText}
            </span>
            {isEditing ? (
                <TextField
                    fullWidth
                    size='small'
                    variant='standard'
                    value={text}
                    onChange={handleTextChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    onBlur={handleBlur}
                    InputProps={{ disableUnderline: true }}
                    style={{ width: width, fontStyle: 'italic', paddingLeft: '2.5px' }}
                />
            ) : (
                <Tooltip title={props.tooltip} enterDelay={1000}>
                    <Typography sx={{ width: width, margin: '2.5px' }}>{text || <i>{defaultText}</i>}</Typography>
                </Tooltip>
            )}
        </Box>
    )
}

export default EditableParagraph
