/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output, FilterDTO_Output } from '@om1/falcon-api'
import { FC, useEffect } from 'react'

import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { NodeContent } from '../nodes/NodeContent'
import { GenericFilterContent } from './GenericDateAwareFilter'
import { useFetchRefs } from './PatientDiagnosisFilter'

export const PatientProcedureFilter: FC<{
    filter: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    const disabled = filter.disabled
    const { refs, fetchRefs } = useFetchRefs()

    useEffect(() => {
        fetchRefs(filter)
    }, [filter, fetchRefs])
    return (
        <NodeContent
            disabled={Boolean(disabled)}
            id={String(filter.blockId)}
            parentType={filter.type}
            allowRelateDrop={!isSubject && !isReference}
            disableAndDrop={isSubject || isReference}
            onClick={onClick}
            selectedBlockId={String(selectedBlockId)}
        >
            <FilterHeader icon={CARD_ICONS.procedure} title='Procedure' />
            <GenericFilterContent
                filter={filter}
                depth={depth}
                onClick={onClick}
                refs={refs}
                table={filter.table}
                isSubject={isSubject}
                isReference={isReference}
            />
        </NodeContent>
    )
}
