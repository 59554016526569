/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistantAIMessage } from '../models/AssistantAIMessage'
import type { AssistantConversationCreateDTO } from '../models/AssistantConversationCreateDTO'
import type { AssistantConversationDetailDTO } from '../models/AssistantConversationDetailDTO'
import type { AssistantConversationDTO } from '../models/AssistantConversationDTO'
import type { AssistantConversationPatchDTO } from '../models/AssistantConversationPatchDTO'
import type { AssistantHumanMessage } from '../models/AssistantHumanMessage'
import type { AssistantToolMessage } from '../models/AssistantToolMessage'
import type { ChatModel } from '../models/ChatModel'
import type { PaginatedDTO_AssistantConversationDTO_ } from '../models/PaginatedDTO_AssistantConversationDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ExplorerAssistantConversationService {
    /**
     * Index
     * @returns PaginatedDTO_AssistantConversationDTO_ Successful Response
     * @throws ApiError
     */
    public static indexExplorerAssistantConversationGet({
        referenceCohortId,
        page = 1,
        limit = 10
    }: {
        /**
         * Filter conversations by reference cohort ID
         */
        referenceCohortId?: string | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_AssistantConversationDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/assistant-conversation',
            query: {
                reference_cohort_id: referenceCohortId,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns AssistantConversationDTO Successful Response
     * @throws ApiError
     */
    public static createExplorerAssistantConversationPost({
        requestBody
    }: {
        requestBody: AssistantConversationCreateDTO
    }): CancelablePromise<AssistantConversationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/explorer/assistant-conversation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns AssistantConversationDetailDTO Successful Response
     * @throws ApiError
     */
    public static getExplorerAssistantConversationIdGet({
        id,
        model = 'gpt-4o'
    }: {
        id: string
        model?: ChatModel
    }): CancelablePromise<AssistantConversationDetailDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/assistant-conversation/{id}',
            path: {
                id: id
            },
            query: {
                model: model
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns AssistantConversationDTO Successful Response
     * @throws ApiError
     */
    public static patchExplorerAssistantConversationIdPatch({
        id,
        requestBody
    }: {
        id: string
        requestBody: AssistantConversationPatchDTO
    }): CancelablePromise<AssistantConversationDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/explorer/assistant-conversation/{id}',
            path: {
                id: id
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteExplorerAssistantConversationIdDelete({ id }: { id: string }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/explorer/assistant-conversation/{id}',
            path: {
                id: id
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Assistant Invoke
     * @returns any Successful Response
     * @throws ApiError
     */
    public static assistantInvokeExplorerAssistantConversationIdInvokePost({
        id,
        requestBody,
        model = 'gpt-4o'
    }: {
        id: string
        requestBody: AssistantHumanMessage
        model?: ChatModel
    }): CancelablePromise<Array<AssistantAIMessage | AssistantHumanMessage | AssistantToolMessage>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/explorer/assistant-conversation/{id}/invoke',
            path: {
                id: id
            },
            query: {
                model: model
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
}
