/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'

interface AuthGuardProps {
    children: React.ReactNode
}

export const Auth0AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
    const { isLoading, error } = useAuth0()

    if (isLoading) {
        return (
            <div className='min-h-screen flex items-center justify-center'>
                <div className='text-center'>
                    <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mb-4'></div>
                    <p className='text-gray-600'>Loading application...</p>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className='min-h-screen flex items-center justify-center'>
                <div className='text-center'>
                    <h1 className='text-2xl font-bold text-red-600 mb-4'>Authentication Error</h1>
                    <p className='text-gray-600'>{error.message}</p>
                </div>
            </div>
        )
    }

    return <>{children}</>
}
