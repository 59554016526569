import { Auth0Provider } from '@auth0/auth0-react'
import { t } from '@lingui/macro'
import { withConfig } from '@om1/platform-utils'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { auth0Config, Auth0ConfigInterface } from './auth0-config'

interface Auth0PlatformProviderComponentProps {
    children: JSX.Element
    config: Auth0ConfigInterface
}

export const Auth0PlatformProviderComponent: React.FunctionComponent<Auth0PlatformProviderComponentProps> = ({
    children,
    config
}: {
    children: JSX.Element
    config: Auth0ConfigInterface
}) => {
    const history = useHistory()
    let { auth0ClientId, auth0Domain, auth0AudienceDomain, auth0PlatformScopes } = config

    const onRedirectCallback = (appState) => {
        if (appState?.from) {
            console.log(t`Redirecting to:`, appState.from)
            history.push(appState.from)
        }
    }

    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            authorizationParams={{
                grant_type: 'authorization_code',
                redirect_uri: window.location.origin,
                audience: `${auth0AudienceDomain}/explorer`,
                scope: auth0PlatformScopes
            }}
            cacheLocation='localstorage'
            useRefreshTokens={false}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

export const Auth0PlatformProvider = withConfig<Auth0PlatformProviderComponentProps>(Auth0PlatformProviderComponent, auth0Config)
