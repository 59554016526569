import { useConversation } from './useExplorerAssistantConversation'
import { useCohort } from './useExplorerCohorts'

export function useCohortFromParams(match) {
    const { cohortId } = match.params
    return {
        ...useCohort(cohortId, false),
        cohortId
    }
}

export function useConversationFromParams(match) {
    const { conversationId } = match.params
    return {
        ...useConversation(conversationId),
        conversationId
    }
}
