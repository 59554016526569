import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { PlatformRoute } from '@om1/cohort-module'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Link, LinkProps, Route, RouteProps, useLocation } from 'react-router-dom'
import { FilterTabBarDivider, RoutedFilterTabBarButton } from './FilterTabBar'

export const MainContainer = styled('div')`
    display: flex;
    flex-direction: column;
    min-height: ${window.innerHeight}px;
    background-color: #f5f6f6;
    flex: 1;
    flex-grow: 1;
    overscroll-behavior: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: auto;
`

export const HeaderContainer = styled('div')`
    flex-basis: 56px;
    z-index: 3;
`

export const PageContainer = styled('div')`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    min-width: 0;
`
export const SidebarContainer = styled('div')`
    flex-basis: 220px;
    z-index: 2;
`
export const ContentContainerStyle = styled('div')`
    flex: 1;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-width: 0;
`

export const RowContainer = styled('div')`
    display: flex;
    justify-content: space-between;
`
export const TabContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 270px;
`

export const BreadcrumbLink = styled(Link)({
    height: '17px',
    color: '#555559',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '18px',
    textDecoration: 'none'
})

interface RoutedRoutedBreadcrumbLinkProps {
    preserveQueryParams?: boolean
}

export const RoutedBreadcrumbLink: FunctionComponent<RoutedRoutedBreadcrumbLinkProps & LinkProps & { route: RouteProps }> = ({
    children,
    ...props
}) => {
    const location = useLocation() // Get the current location

    const targetURL = {
        pathname: props.route.path,
        search: props.preserveQueryParams ? location.search : ''
    }

    return <BreadcrumbLink to={targetURL.pathname + targetURL.search}>{children}</BreadcrumbLink>
}

interface ScrollableContentContainerProps {
    drawerOpen?: boolean
}

const ScrollableContentContainer = styled('div')<ScrollableContentContainerProps>`
    padding: 32px 10px;
    overflow: auto;
    margin-left: ${(props) => (props.drawerOpen ? '200px' : '0px')};
`

interface ContentPaddingProps {
    padX?: boolean
    padY?: boolean
}

export const InvertContentPadding = styled(Box, { shouldForwardProp: (prop: string) => !['padX', 'padY'].includes(prop) })<ContentPaddingProps>`
    margin-left: ${(props) => (props.padX ? '-60px' : 0)};
    margin-right: ${(props) => (props.padX ? '-60px' : 0)};
    margin-bottom: ${(props) => (props.padY ? '-10px' : 0)};
    height: 100%;
`

export const ContentPadding = styled(Box, { shouldForwardProp: (prop: string) => !['padX', 'padY'].includes(prop) })<ContentPaddingProps>`
    padding-left: ${(props) => (props.padX ? '60px' : 0)};
    padding-right: ${(props) => (props.padX ? '60px' : 0)};
    padding-top: ${(props) => (props.padY ? '32px' : 0)};
    height: 100%;
`

export const ContentContainer = (props: PropsWithChildren<{ drawerOpen?: boolean }>) => {
    return (
        <ContentContainerStyle>
            <ScrollableContentContainer drawerOpen={props.drawerOpen}>{props.children}</ScrollableContentContainer>
        </ContentContainerStyle>
    )
}

export const ShowInLargeView = { xs: 'none', md: 'flex' }

export const ShowInSmallView = { xs: 'flex', md: 'none' }

export function createTabs<
    T extends Omit<PlatformRoute, 'component'> & {
        url: string
        matchRoute: {
            path: string
        }
    }
>(routingConfigObject: T[], pathname: string): JSX.Element[] {
    return routingConfigObject.map((tab) => (
        <div key={`tabbuttondiv-${tab.route}`}>
            <RoutedFilterTabBarButton
                key={`tabbutton-${tab.route}`}
                to={tab.url}
                route={tab.matchRoute}
                active={pathname.includes(tab.route)}
                preserveQueryParams={true}
            >
                {tab.name}
            </RoutedFilterTabBarButton>
            <FilterTabBarDivider />
        </div>
    ))
}

export function createPages<
    T extends PlatformRoute & {
        url: string
        matchRoute: {
            path: string
        }
    }
>(routingConfigObject: T[], matchUrlValue: string): JSX.Element[] {
    return routingConfigObject.map((tab) => <Route key={tab.route} path={matchUrlValue + tab.route} exact component={tab.component} />)
}
