/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { NodeContent } from '../nodes/NodeContent'
import { GenericFilterContent } from './GenericDateAwareFilter'
import { GenericPatientFilter } from './GenericPatientFilter'

export const PatientFilter: FC<{
    filter: FilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    if (filter.field === 'has_labs') {
        return (
            <NodeContent
                id={String(filter.blockId)}
                parentType={filter.type}
                allowRelateDrop={!isSubject && !isReference}
                onClick={onClick}
                selectedBlockId={String(selectedBlockId)}
            >
                <FilterHeader icon={CARD_ICONS.lab} title='Lab Tests' />
                <GenericFilterContent
                    filter={filter}
                    depth={depth}
                    onClick={onClick}
                    valueOverwrite={(value: string): string => {
                        return value === 'True' ? 'Has any lab test results' : value
                    }}
                    table={filter.table}
                />
            </NodeContent>
        )
    } else {
        return <GenericPatientFilter filter={filter} depth={depth} />
    }
}
