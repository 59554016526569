import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Box, Button, DialogActions, DialogContent, MenuItem, Select, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { cohortBlocksEditActions, CriterionNode, FilterValueQualifier, QualifierOperator, QualifierType } from '../../../state'
import { dateToDTODateString, DTODateStringToDate } from '../utils/date-utils'
import { findQualifierByType } from '../utils/finders'
import { ObservationScoreField } from './ObservationScoreEditDialogComponent'

interface ObservationDateScoreEditFormValues {
    operator: QualifierOperator
    field: string
    primaryInput: Date
    secondaryInput?: Date
}

export type ObservationDateScoreEditDialogComponentProps = FrameworkComponentProps<
    {
        criteria: CriterionNode
    },
    typeof cohortBlocksEditActions
>

export const ObservationDateScoreEditDialogComponent = ({ state, actions }: ObservationDateScoreEditDialogComponentProps) => {
    const { criteria } = state

    const validationSchema = Yup.object().shape({
        operator: Yup.string(),
        field: Yup.string().oneOf(Object.values(ObservationScoreField)),
        primaryInput: Yup.date().required(),
        secondaryInput: Yup.date()
            .when('operator', {
                is: (operator: string) => operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween,
                then: (schema) => {
                    return schema.typeError(t`End date is required`).required()
                },
                otherwise: () => Yup.date().notRequired()
            })
            .min(Yup.ref('primaryInput'), t`End date must be more recent than start date`)
    })

    const initialValue = useMemo(() => {
        const existingQualifier = findQualifierByType<FilterValueQualifier>(criteria.qualifiers, QualifierType.FilterQualifierDTO)

        if (!existingQualifier) {
            return {
                operator: QualifierOperator.GreaterThan,
                field: ObservationScoreField.Date,
                primaryInput: new Date()
            }
        }

        const { operator, values, field } = existingQualifier

        return {
            operator,
            field: field.replace('_result', ''),
            primaryInput: DTODateStringToDate(`${values[0]}`),
            secondaryInput:
                values.length > 1 && (operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween)
                    ? DTODateStringToDate(`${values[1]}`)
                    : undefined
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors },
        resetField
    } = useForm<ObservationDateScoreEditFormValues>({
        defaultValues: {
            operator: initialValue.operator,
            field: initialValue.field,
            primaryInput: initialValue.primaryInput,
            secondaryInput: initialValue.secondaryInput ? initialValue.secondaryInput : undefined
        },
        resolver: yupResolver(validationSchema)
    })

    const onCancel = () => {
        actions.observationScoreEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<ObservationDateScoreEditFormValues> = (formValues: ObservationDateScoreEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: FilterValueQualifier = formValuesToFilterValueQualifier(formValues)
        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO)
        qualifiers.push(newQualifier)

        actions.observationScoreEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    const operatorWatcher = watch('operator')

    const renderSingleInput = (fieldName: 'primaryInput' | 'secondaryInput') => (
        <Controller
            control={control}
            name={fieldName}
            render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        {...restField}
                        {...register(fieldName)}
                        sx={{ marginRight: 1, flex: 1 }}
                        data-testid={`input-observation-numeric-value-${fieldName === 'primaryInput' ? 'primary' : 'secondary'}`}
                        onChange={(e) => {
                            if (e) {
                                onChange(e)
                            }
                        }}
                        defaultValue={initialValue[fieldName]}
                        disableFuture={true}
                        views={['year', 'month', 'day']}
                    />
                </LocalizationProvider>
            )}
        />
    )

    const renderValueRange = () => (
        <>
            {renderSingleInput('primaryInput')}
            <Box flexShrink={0} marginRight={1}>
                <Trans>and</Trans>
            </Box>
            {renderSingleInput('secondaryInput')}
        </>
    )

    const renderFormFields = () => {
        if (operatorWatcher === QualifierOperator.Between || operatorWatcher === QualifierOperator.NotBetween) {
            return renderValueRange()
        }

        return renderSingleInput('primaryInput')
    }

    const renderFullWidthErrorMessage = (fieldName: 'primaryInput' | 'secondaryInput') => {
        if (!errors[fieldName]) {
            return <></>
        }

        return (
            <Box marginBottom={1} alignSelf='flex-end'>
                <Typography color='error'>{errors[fieldName]?.message}</Typography>
            </Box>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Box flexShrink={0} marginRight={1}>
                        <Trans>Observation Date</Trans>
                    </Box>
                    <Controller
                        control={control}
                        name='operator'
                        render={({ field: { onChange, value } }) => (
                            <Select
                                id='operator'
                                labelId='operator-label'
                                sx={{ flex: 1, marginRight: 1 }}
                                required
                                value={value}
                                onChange={(e) => {
                                    const newValue = e.target.value as QualifierOperator
                                    onChange(newValue)
                                    // Reset the secondary input if the new operator would hide the secondary input
                                    if (newValue !== QualifierOperator.Between && newValue !== QualifierOperator.NotBetween) {
                                        resetField('secondaryInput')
                                    }
                                }}
                            >
                                <MenuItem value={QualifierOperator.Equals}>
                                    <Trans>On</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.NotEquals}>
                                    <Trans>Not On</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.GreaterThan}>
                                    <Trans>After</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.GreaterThanOrEquals}>
                                    <Trans>On or After</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.LessThan}>
                                    <Trans>Before</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.LessThanOrEquals}>
                                    <Trans>Before or On</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.Between}>
                                    <Trans>Between</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.NotBetween}>
                                    <Trans>Not Between</Trans>
                                </MenuItem>
                            </Select>
                        )}
                    />
                    {renderFormFields()}
                </Box>
                {(!!errors['primaryInput'] || !!errors['secondaryInput']) && (
                    <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='right' rowGap={1} marginTop={2}>
                        {renderFullWidthErrorMessage('primaryInput')}
                        {renderFullWidthErrorMessage('secondaryInput')}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant='contained' type='submit'>
                    <Trans>Save</Trans>
                </Button>
            </DialogActions>
        </form>
    )
}

function formValuesToFilterValueQualifier(formValues: ObservationDateScoreEditFormValues): FilterValueQualifier {
    const values = [formValues.primaryInput]
    // If the secondary input is defined and the operator is between or not between, add it to the values array
    if (formValues.secondaryInput && (formValues.operator === QualifierOperator.Between || formValues.operator === QualifierOperator.NotBetween)) {
        values.push(formValues.secondaryInput)
    }

    return {
        type: QualifierType.FilterQualifierDTO,
        field: `${formValues.field}_result`,
        operator: formValues.operator,
        values: values.map((value) => dateToDTODateString(value))
    }
}
