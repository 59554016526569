import { Auth0State, auth0Actions } from '@om1/platform-authentication'
import { FrameworkComponentProps, PlatformPermissions, connectRedux } from '@om1/platform-utils'
import { FunctionComponent, ReactElement, useEffect, useState } from 'react'

export type PermissionProtectedRouteComponentProps = FrameworkComponentProps<
    Auth0State,
    typeof auth0Actions,
    {
        permissions: PlatformPermissions[]
        accessDeniedComponent: ReactElement
        protectedChild: ReactElement
    }
>

export const PermissionProtectedRouteComponent: FunctionComponent<PermissionProtectedRouteComponentProps> = ({ state, props }) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (state.permissions !== undefined) {
            setIsLoading(false)
        }
    }, [state.permissions])

    if (isLoading) {
        return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
    }

    const hasRole = props.permissions.every((permission) => state.permissions!.includes(permission))
    return <>{hasRole ? props.protectedChild : props.accessDeniedComponent}</>
}

export function createPermissionProtectedRouteComponent<TState extends { auth0: Auth0State }>() {
    return connectRedux(PermissionProtectedRouteComponent, (state: TState) => state.auth0, { ...auth0Actions })
}

export type PermissionProtectedRoute = ReturnType<typeof createPermissionProtectedRouteComponent>
