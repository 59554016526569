/* eslint-disable string-to-lingui/missing-lingui-transformation */
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { CountDistinctQualifierDTO_Output, DateAwareFilterDTO_Output, DateQualifierDTO_Output, FilterDTO_Output, RefTable } from '@om1/falcon-api'
import { FC } from 'react'
import { NodeContent } from '../nodes/NodeContent'
import { QueryNode } from '../nodes/QueryNode'
import { Qualifier } from '../qualifiers/Qualifier'

const processFilterValues = (filter: FilterDTO_Output | DateAwareFilterDTO_Output, refs?: Array<Record<string, any>>) => {
    const rawValues = Array.isArray(filter.values) ? filter.values : [filter.values]
    const convertedValues: string[] = []

    for (const value of rawValues) {
        if (refs && refs.length > 0) {
            switch (filter.table) {
                case 'patient_diagnosis': {
                    const patient_diagnosis_boc_cui_ref = refs.find((ref) => ref.boc_cui === value)
                    const patient_diagnosis_diagnosis_concept_id_ref = refs.find((ref) => ref.diagnosis_concept_id === value)
                    if (patient_diagnosis_boc_cui_ref) {
                        convertedValues.push(patient_diagnosis_boc_cui_ref.boc_name)
                    } else if (patient_diagnosis_diagnosis_concept_id_ref) {
                        convertedValues.push(patient_diagnosis_diagnosis_concept_id_ref.diagnosis)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                case 'patient_lab': {
                    const patient_lab_ref = refs.find((ref) => ref.lab_concept_id === value)
                    if (patient_lab_ref) {
                        convertedValues.push(patient_lab_ref.lab)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                case 'patient_medication': {
                    const patient_medication_boc_cui_ref = refs.find((ref) => ref.boc_cui === value)
                    const patient_medication_ref = refs.find((ref) => ref.id === value)
                    const patient_medication_medication_ndc_code_ref = refs.find((ref) => ref.ndc_code === value)
                    if (patient_medication_boc_cui_ref) {
                        convertedValues.push(patient_medication_boc_cui_ref.boc_name)
                    } else if (patient_medication_ref) {
                        convertedValues.push(patient_medication_ref.medication)
                    } else if (patient_medication_medication_ndc_code_ref) {
                        convertedValues.push(patient_medication_medication_ndc_code_ref.ndc_name)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                default: {
                    const ref = refs.find((ref) => ref.boc_cui === value)
                    if (ref) {
                        convertedValues.push(ref.boc_name)
                    } else {
                        convertedValues.push(value)
                    }
                }
            }
        } else {
            convertedValues.push(value)
        }
    }

    return convertedValues
}

interface ListWithMoreProps {
    items: string[]
    maxItems?: number
    itemCount: number
}

export const ListWithMore: FC<ListWithMoreProps> = ({ items, maxItems = 2, itemCount }) => (
    <div style={{ marginTop: '0.25rem', paddingBottom: '0.25rem' }}>
        {items.slice(0, maxItems).map((item, index) => (
            <div key={index} style={{ marginBottom: '0.25rem' }}>
                {item ? item.trim() : ''}
            </div>
        ))}
        {itemCount > maxItems && (
            <Tooltip title={items.join('\n')} placement='bottom-start'>
                <div style={{ fontStyle: 'italic', cursor: 'help' }}>... and {itemCount - maxItems} more</div>
            </Tooltip>
        )}
    </div>
)

const SimpleFilterDetails: FC<{
    filter: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    refs?: Array<Record<string, any>>
    valueOverwrite?: (value: string) => string
    table?: string
    disableClick?: boolean
    isSubject?: boolean
    isReference?: boolean
}> = ({ filter, depth, onClick, refs, valueOverwrite, table, isSubject, isReference }) => {
    const convertedValues = processFilterValues(filter, refs)
    const overwrittenValues = valueOverwrite ? convertedValues.map(valueOverwrite) : convertedValues
    const hasCountDistinctQualifier =
        filter.qualifiers && (filter.qualifiers as unknown as any[]).some((qualifier: any) => qualifier.type === 'CountDistinctQualifierDTO')
    return (
        <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
            <div style={{ color: '#4B5563' }}>
                <ListWithMore items={overwrittenValues} itemCount={overwrittenValues.length} />
                <>
                    <div style={{ gridColumn: 'span 2 / span 2' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            {!hasCountDistinctQualifier && !(filter.table === ('observation_period' as RefTable)) && !isReference && (
                                <Qualifier
                                    key={`${filter.blockId}-intrinsic-distinct`}
                                    qualifier={
                                        {
                                            type: 'CountDistinctQualifierDTO',
                                            field: filter.field,
                                            value: 1,
                                            operator: 'in',
                                            requiresRecordLevelCube: false,
                                            measureType: 'count_distinct'
                                        } as CountDistinctQualifierDTO_Output
                                    }
                                    depth={depth + 1}
                                    parent={filter}
                                    onClick={onClick}
                                    table={table}
                                    qualifierIndex={-1}
                                />
                            )}
                            {!hasCountDistinctQualifier && !(filter.table === ('observation_period' as RefTable)) && isReference && (
                                <NodeContent id={`${filter.blockId}-intrinsic-first`} parentType={filter.type} onClick={onClick} isQualifier>
                                    <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>
                                        <div>{`${
                                            (filter as DateAwareFilterDTO_Output).dateField === 'first_record_date' ||
                                            (filter as DateAwareFilterDTO_Output).dateField === 'first_observation_date'
                                                ? filter.table === 'patient_medication'
                                                    ? 'Medication Initiation'
                                                    : 'First Encounter'
                                                : 'Latest Encounter'
                                        }`}</div>
                                    </div>
                                </NodeContent>
                            )}
                            {(filter.qualifiers || ([] as unknown as DateQualifierDTO_Output[])).map(
                                (qualifier: DateQualifierDTO_Output, index: number) => {
                                    return (
                                        <Qualifier
                                            key={`${filter.blockId}-${index}`}
                                            qualifier={qualifier}
                                            depth={depth + 1}
                                            parent={filter}
                                            onClick={onClick}
                                            table={table}
                                            qualifierIndex={index}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}

export const GenericFilterContent: FC<{
    filter: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    refs?: Array<Record<string, any>>
    valueOverwrite?: (value: string) => string
    table?: string
    disableClick?: boolean
    isSubject?: boolean
    isReference?: boolean
}> = ({ filter, depth, onClick, refs, valueOverwrite, table, disableClick = false, isSubject, isReference }) => {
    return (
        <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
            <SimpleFilterDetails
                filter={filter}
                depth={depth}
                onClick={onClick}
                refs={refs}
                valueOverwrite={valueOverwrite}
                table={table}
                disableClick={disableClick}
                isSubject={isSubject}
                isReference={isReference}
            />
        </div>
    )
}

export const GenericDateAwareFilter: FC<{
    node: DateAwareFilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    refs?: Array<Record<string, any>>
    selectedBlockId?: number
    valueOverwrite?: (value: string) => string
    table?: string
}> = ({ node, depth, onClick, refs, selectedBlockId, valueOverwrite, table, isSubject, isReference }) => {
    return (
        <QueryNode
            depth={depth}
            title={`${node.table} date aware filter ${node.blockId}`}
            blockId={node.blockId}
            onClick={onClick}
            selectedBlockId={selectedBlockId}
        >
            <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
                <SimpleFilterDetails
                    filter={node}
                    depth={depth}
                    refs={refs}
                    valueOverwrite={valueOverwrite}
                    table={table}
                    isSubject={isSubject}
                    isReference={isReference}
                />
            </div>
        </QueryNode>
    )
}
