/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ExceptDTO_Output, QueryDTO_Output } from '@om1/falcon-api'
import { Dispatch, FC, SetStateAction } from 'react'
import { QueryBlock } from './QueryBlock'

const isExceptDTO = (node: any): node is ExceptDTO_Output => {
    return node.type === 'ExceptDTO'
}

const isBaseDTOFilter = (node: QueryDTO_Output | null | undefined): boolean => {
    return Boolean(
        node &&
            node.filters?.length === 1 &&
            isExceptDTO(node.filters[0]) &&
            (node.filters[0].except.length === 1 || node.filters[0].except.length === 2) &&
            node.filters[0].except.every((exceptNode) => exceptNode.type === 'AndDTO')
    )
}

export const Query: FC<{
    query?: QueryDTO_Output | null
    selectedBlockId?: number | null
    setSelectedBlockId?: Dispatch<SetStateAction<number | undefined>>
    isBase?: boolean
}> = ({ query, selectedBlockId, setSelectedBlockId, isBase }) => {
    const isBaseDTO = isBase && isBaseDTOFilter(query)
    return (
        <div style={{ minHeight: '66.54dvh', marginBottom: '25px' }}>
            {isBaseDTO && query?.filters && isExceptDTO(query.filters[0]) && (
                <>
                    <h4>Inclusion Criteria: </h4>
                    <QueryBlock
                        key={`query-block-inclusion`}
                        block={{ node: query.filters[0].except[0] }}
                        selectedBlockId={selectedBlockId}
                        onClick={(blockId: number) => {
                            setSelectedBlockId && setSelectedBlockId(blockId)
                        }}
                    />
                    {query.filters[0].except.length > 1 && (
                        <>
                            <h4>Exclusion Criteria: </h4>
                            <QueryBlock
                                key={`query-block-exclusion`}
                                block={{ node: query.filters[0].except[1] }}
                                selectedBlockId={selectedBlockId}
                                onClick={(blockId: number) => {
                                    setSelectedBlockId && setSelectedBlockId(blockId)
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {!isBaseDTO &&
                query?.filters?.map((filter, index) => {
                    return (
                        <QueryBlock
                            key={`query-block-${index}`}
                            block={{ node: filter }}
                            selectedBlockId={selectedBlockId}
                            onClick={(blockId: number) => {
                                setSelectedBlockId && setSelectedBlockId(blockId)
                            }}
                        />
                    )
                })}
        </div>
    )
}
