import { ExplorerTasksService, OpenAPI, TaskStatus } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call } from 'redux-saga/effects'
import { cohortAnalyticsActions, reportsActions } from '../state'
import { analyticsSocketListen, onTaskCreationError } from './analytics-utils'

export function createGetCohortReportDataSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportTaskGet>) {
        const params = {
            cohortId: action.payload.cohortId!,
            reportId: action.payload.reportId!,
            reportType: action.payload.reportType!
        }
        const { cohortId, reportId, reportType } = params
        let taskId: string | undefined
        try {
            const taskStatus: TaskStatus = yield call(ExplorerTasksService.executeReportExplorerTasksReportReportIdExecuteGet, {
                reportId: reportId
            })
            taskId = taskStatus.taskId
            if (taskId) {
                const token = OpenAPI.TOKEN
                yield call(analyticsSocketListen, { cohortId, reportType, actions: cohortAnalyticsActions, taskId, token })
            }
        } catch (error) {
            yield call(onTaskCreationError, { cohortId, reportType, actions: cohortAnalyticsActions })
            yield handleApiError(error)
        }
    }
}

export type GetCohortReportDataSaga = ReturnType<typeof createGetCohortReportDataSaga>
