/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateQualifierDTO_Output, FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { QueryNode } from '../nodes/QueryNode'
import { Qualifier } from '../qualifiers/Qualifier'
import { ObservationPeriodFilter } from './ObservationPeriodFilter'
import { PatientDiagnosisFilter } from './PatientDiagnosisFilter'
import { PatientEncounterFilter } from './PatientEncounterFilter'
import { PatientExternalCohortFilter } from './PatientExternalCohortFilter'
import { PatientFilter } from './PatientFilter'
import { PatientLabFilter } from './PatientLabFilter'
import { PatientMedicationFilter } from './PatientMedicationFilter'
import { PatientNoteFilter } from './PatientNoteFilter'
import { PatientObservationFilter } from './PatientObservationFilter'
import { PatientProcedureFilter } from './PatientProcedureFilter'

export const Filter: FC<{
    node: FilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ node, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    switch (node.table) {
        case 'patient_note':
            return (
                <PatientNoteFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient':
            return (
                <PatientFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_encounter':
            return <PatientEncounterFilter filter={node} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId} />
        case 'patient_procedure':
            return (
                <PatientProcedureFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_medication':
            return (
                <PatientMedicationFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_diagnosis':
            return (
                <PatientDiagnosisFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_external_cohort':
            return (
                <PatientExternalCohortFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'observation_period':
            return (
                <ObservationPeriodFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_observation':
            return (
                <PatientObservationFilter
                    filter={node}
                    depth={depth}
                    isSubject={isSubject}
                    isReference={isReference}
                    onClick={onClick}
                    selectedBlockId={selectedBlockId}
                />
            )
        case 'patient_lab':
            return <PatientLabFilter filter={node} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId} />
        default:
            return (
                <QueryNode depth={depth} title={`FilterDTO`} onClick={onClick} selectedBlockId={selectedBlockId}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '0.25rem', fontSize: '0.875rem' }}>
                            <span style={{ color: '#4B5563' }}>Table:</span>
                            <span>
                                {node.table}
                                <span style={{ marginLeft: '0.5rem', color: 'red', display: 'inline-flex', alignItems: 'center' }}>
                                    ⚠️
                                    <span style={{ marginLeft: '0.25rem' }}>invalid</span>
                                </span>
                            </span>
                            <span style={{ color: '#4B5563' }}>Field:</span>
                            <span>{node.field}</span>
                            <span style={{ color: '#4B5563' }}>Operator:</span>
                            <span>{node.operator}</span>
                            <span style={{ color: '#4B5563' }}>Values:</span>
                            <span>{Array.isArray(node.values) ? node.values.join(', ') : node.values}</span>
                            {node.qualifiers && (node.qualifiers as any[]).length > 0 && (
                                <div>
                                    {(node.qualifiers as any[]).map((q: DateQualifierDTO_Output, index: number) => {
                                        return (
                                            <Qualifier
                                                key={`${node.blockId}-${index}`}
                                                qualifier={q}
                                                depth={depth + 1}
                                                parent={node}
                                                onClick={onClick}
                                                table={node.table}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </QueryNode>
            )
    }
}
