/* eslint-disable string-to-lingui/missing-lingui-transformation */

import { CohortRoutes } from '@om1/cohort-module'
import { Routes } from '@om1/platform-routing'
import { Redirect, Route, Switch } from 'react-router-dom'
import ChatView from '../views/ChatView'

export function ExplorerLayout() {
    return (
        <Switch>
            <Route path={`/${Routes.COHORTBUILD}/${CohortRoutes.ASSISTANT}/conversation/:conversationId`} component={ChatView} />
            <Redirect to={`/${Routes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/`} />
        </Switch>
    )
}
