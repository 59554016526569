import { User } from '@auth0/auth0-react'
import { t, Trans } from '@lingui/macro'
import AppsIcon from '@mui/icons-material/Apps'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Avatar, Box, Button, Menu, MenuItem, Tooltip } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'

import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/system'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { PlatformPermissions } from '@om1/platform-utils'
import * as React from 'react'
import { ComponentType, ReactElement, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isCohortBuilderPrintViewUrl } from '../../../../../apps/platform/src/pages/CohortPage'
import { MenuItemLabel } from '../appbar/MainMenuItem'
import PermissionProtectedSideBarItem from '../PermissionProtectedSideBarItem'

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
    color: white;
`

const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
    background: white;
    color: #002d72;
    border-radius: 12px;
    height: 15px;
    width: 15px;
    padding: 2px;
`

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '65px',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}))

export type SideBarItem = {
    route: string
    name: React.ReactElement<any, any>
    icon: React.ReactElement<any, any>
    permissions?: PlatformPermissions[]
    userPermissions?: string[]
}

const SideBarListItem: React.FunctionComponent<
    SideBarItem & { active: boolean; open: boolean; onClick: React.MouseEventHandler<HTMLAnchorElement> }
> = (props) => {
    const item = (
        <>
            <ListItem key={props.name.key} disablePadding sx={{ display: 'block', backgroundColor: props.active ? '#002D72' : '#8BB8E8' }}>
                <Button
                    component={Link}
                    to={toPath(props.route as Routes)}
                    sx={{
                        height: 48,
                        justifyContent: 'initial',
                        alignItems: 'center',
                        px: 2.4,
                        width: '100%'
                    }}
                    onClick={props.onClick}
                >
                    <LightTooltip title={props.name} placement='right' enterDelay={500} sx={{ opacity: props.open ? 0 : 1 }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: 3,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                            }}
                        >
                            {props.icon}
                        </ListItemIcon>
                    </LightTooltip>
                    <ListItemText
                        primary={props.name}
                        sx={{ opacity: props.open ? 1 : 0 }}
                        primaryTypographyProps={{
                            sx: {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                letterSpacing: '0',
                                lineHeight: '18px',
                                color: '#FFFFFF'
                            }
                        }}
                    />
                </Button>
            </ListItem>
            <Divider sx={{ backgroundColor: '#FFFFFF' }} />
        </>
    )
    if (props.permissions && props.permissions.length > 0) {
        return (
            <PermissionProtectedSideBarItem permissions={props.permissions} userPermissions={props.userPermissions}>
                {item}
            </PermissionProtectedSideBarItem>
        )
    } else {
        return item
    }
}

export const ResponsiveSideBar: React.FunctionComponent<
    React.PropsWithChildren<{
        logo?: React.ReactElement
        orgName?: string
        user?: User
        logoutFunction?: () => void
        sideBarItems: {
            route: string
            component: ComponentType<any>
            permissions: PlatformPermissions[]
            name: ReactElement<any, any>
            icon: ReactElement<any, any>
        }[]
        showHelpDialog?: () => void
        userPermissions?: string[]
    }>
> = (props) => {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)

    const toggleDrawerOpen = () => {
        setOpen(!open)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const location = useLocation()

    const handleHelpDialogOpen = () => {
        props.showHelpDialog && props.showHelpDialog()
    }

    const headerless: boolean = useMemo(() => {
        return isCohortBuilderPrintViewUrl(location.pathname)
    }, [location.pathname])

    if (location) {
        return (
            <Box sx={{ display: 'flex', flex: 1, minWidth: 0 }}>
                <AppBar position='fixed' open={open}>
                    <Toolbar variant='dense'>
                        {!headerless && (
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                onClick={toggleDrawerOpen}
                                edge='start'
                                sx={{
                                    marginRight: 5,
                                    ...(open && { display: 'none' })
                                }}
                            >
                                <AppsIcon sx={{ color: '#FFFFFF' }} />
                            </IconButton>
                        )}
                        {props.logo}
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} />
                        {props.user && props.orgName && props.orgName !== 'om1' && (
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>{props.orgName.toUpperCase()}</Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0, borderRadius: '0px', paddingLeft: '30px', marginRight: '10px', height: '30px' }}
                            >
                                <Avatar
                                    sx={{
                                        borderRadius: '20px',
                                        color: 'rgb(0, 45, 114)',
                                        backgroundColor: 'white',
                                        margin: '10px',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                >
                                    {props.user?.nickname ? props.user?.nickname[0] : ''}
                                    {props.user?.nickname ? props.user?.nickname[1] : ''}
                                </Avatar>
                                <MenuItemLabel>
                                    {props.user?.given_name ? (
                                        <>{props.user?.given_name}</>
                                    ) : props.user?.nickname ? (
                                        <>{props.user?.nickname}</>
                                    ) : (
                                        <Trans>Account</Trans>
                                    )}
                                </MenuItemLabel>
                                <StyledArrowDropDownIcon />
                            </IconButton>
                            <Menu
                                sx={{ mt: '18px', ml: '-10px' }}
                                id='menu-appbar-large'
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={t`Logout`} onClick={props.logoutFunction}>
                                    <Trans>Logout</Trans>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={<Trans>Need Help?</Trans>} sx={{ p: 0 }} id={'helpTooltip'}>
                                <IconButton sx={{ p: 0 }} key={'questionMarkIcon'} aria-label='help' onClick={handleHelpDialogOpen}>
                                    <StyledQuestionMarkIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>
                {!headerless && (
                    <Drawer variant='permanent' open={open}>
                        <DrawerHeader sx={{ marginTop: '-13px', backgroundColor: '#8BB8E8' }}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? (
                                    <ChevronRightIcon sx={{ color: '#FFFFFF' }} />
                                ) : (
                                    <ChevronLeftIcon sx={{ color: '#FFFFFF' }} />
                                )}
                            </IconButton>
                        </DrawerHeader>
                        <List sx={{ backgroundColor: '#8BB8E8', height: '100%' }}>
                            {props.sideBarItems.map(
                                (
                                    item: {
                                        route: string
                                        component: ComponentType<any>
                                        permissions: PlatformPermissions[]
                                        name: ReactElement<any, any>
                                        icon: ReactElement<any, any>
                                    },
                                    index
                                ) => {
                                    return (
                                        <SideBarListItem
                                            key={index}
                                            route={item.route}
                                            name={item.name}
                                            permissions={item.permissions}
                                            icon={item.icon}
                                            onClick={handleDrawerClose}
                                            active={location.pathname.includes(item.route)}
                                            open={open}
                                            userPermissions={props.userPermissions}
                                        />
                                    )
                                }
                            )}
                        </List>
                    </Drawer>
                )}
                <Box
                    paddingTop={7}
                    component='main'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: 0
                    }}
                >
                    {props.children}
                </Box>
            </Box>
        )
    }
    return null
}
