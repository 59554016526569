/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { NodeContent } from '../nodes/NodeContent'
import { GenericFilterContent } from './GenericDateAwareFilter'

export const PatientNoteFilter: FC<{
    filter: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    const location = useLocation()
    const isEditPath = location.pathname.includes('edit')
    return (
        <NodeContent
            id={String(filter.blockId)}
            parentType={filter.type}
            allowRelateDrop={!isSubject && !isReference}
            onClick={onClick}
            selectedBlockId={String(selectedBlockId)}
            disableClick={isEditPath}
        >
            <FilterHeader icon={CARD_ICONS.note} title='EHR Notes' disableClick={isEditPath} />
            <GenericFilterContent
                filter={filter}
                depth={depth}
                onClick={onClick}
                valueOverwrite={(value: string): string => {
                    return value === 'True' ? '' : value
                }}
                table={filter.table}
                disableClick={isEditPath}
            />
        </NodeContent>
    )
}
