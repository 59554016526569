/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output, FilterDTO_Output, FollowUpLengthQualifierDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { NodeContent } from '../nodes/NodeContent'

export const FollowUpLengthQualifier: FC<{
    qualifier: FollowUpLengthQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, parent, onClick, disableClick = false, qualifierIndex = -1 }) => {
    return (
        <NodeContent
            id={`follow-up-length-qualifier-${parent.blockId}`}
            parentType={qualifier.type}
            onClick={() => {
                onClick && parent?.blockId && onClick(parent?.blockId, qualifierIndex)
            }}
            isQualifier
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
        >
            <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>
                {`At least ${qualifier.amount} ${qualifier.units}${qualifier.amount > 1 ? 's' : ''}`}
            </div>
        </NodeContent>
    )
}
