/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Message } from '@chatscope/chat-ui-kit-react'
import BuildIcon from '@mui/icons-material/Build'
import Tooltip from '@mui/material/Tooltip'
import { marked, MarkedOptions } from 'marked'
import React, { Component, ErrorInfo, ReactElement, ReactNode } from 'react'
import { AiMessage, HumanMessage, Message as MessageType, ToolMessage } from '../lib/message'

// Define a custom renderer
const customRenderer = new marked.Renderer()

// Example: Customize paragraph rendering to reduce whitespace
customRenderer.paragraph = (text) => `<p style="margin: 0px !important;">${text.text}</p>`

customRenderer.heading = ({ tokens, depth }) => {
    const headingText = tokens.map((token) => token.raw).join('')
    return `<h${depth} style="marginBottom: 0px !important;">${headingText}</h${depth}>`
}

customRenderer.listitem = (item) => {
    const listItem = item.tokens
        .map((token) => {
            if (token.type === 'text') {
                const cleanText = token.text.replace(/\*\*/g, '')
                return `<strong>${cleanText}</strong>`
            } else if (token.type === 'list') {
                const subList = token.items
                    .map((subItem) => {
                        const formattedText = subItem.text.replace(/\*\*/g, '').replace(/`([^`]*)`/g, '<code>$1</code>')
                        return `<li>${formattedText}</li>`
                    })
                    .join('')
                return `<ul>${subList}</ul>`
            }
            return ''
        })
        .join('')

    return `<li style="margin: 0px !important;">${listItem}</li>`
}

// Use the custom renderer in the options
const markedOptions: MarkedOptions = {
    renderer: customRenderer
}

export const renderMessage = (message: MessageType, index: number): ReactElement => {
    switch (message.type) {
        case 'tool':
            return <ToolCallResponseComponent message={message} />
        case 'human':
            return <HumanMessageComponent message={message as HumanMessage} />
        case 'ai':
            if (message?.additional_kwargs?.tool_calls && message.additional_kwargs.tool_calls.length > 0) {
                return <ToolCallMessageComponent message={message} />
            } else {
                return <AIMessageComponent message={message as AiMessage} />
            }
        default:
            return <>{JSON.stringify(message)}</>
    }
}

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    }

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Error Boundary caught an error:', error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div style={{ padding: '20px', color: '#666' }}>
                    <h3>Something went wrong</h3>
                    <p>The message couldn&apos;t be displayed properly.</p>
                </div>
            )
        }

        return this.props.children
    }
}

// Common styles
const baseMessageContainerStyle = {
    display: 'flex',
    width: '100%',
    margin: '8px 0'
} as const

const baseMessageStyle = {
    maxWidth: '70%',
    padding: '12px 16px',
    borderRadius: '16px',
    margin: '8px 0',
    wordBreak: 'break-word' as const
}

// Message Components
export const HumanMessageComponent: React.FC<{ message: HumanMessage }> = ({ message }) => {
    return (
        <ErrorBoundary>
            <div key={`message-${message.id}`} style={{ ...baseMessageContainerStyle, justifyContent: 'flex-end' }}>
                <Message
                    model={{
                        message: message.content,
                        sentTime: 'just now',
                        direction: 'outgoing',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{ ...baseMessageStyle, color: '#ffffff' }}
                />
            </div>
        </ErrorBoundary>
    )
}

export const AIMessageComponent: React.FC<{ message: AiMessage }> = ({ message }) => {
    return (
        <ErrorBoundary>
            <div key={`message-${message.id}`} style={{ ...baseMessageContainerStyle, justifyContent: 'flex-start', padding: '2px 4px' }}>
                <Message
                    model={{
                        message: marked.parse(message.content, markedOptions) as string,
                        sentTime: 'just now',
                        direction: 'incoming',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#000000',
                        padding: '2px 4px',
                        margin: '1px 0',
                        lineHeight: '1.2'
                    }}
                />
            </div>
        </ErrorBoundary>
    )
}

const BaseToolContainer = ({ children, style }: any) => (
    <div style={{ ...baseMessageContainerStyle, justifyContent: 'flex-start', ...style }}>{children}</div>
)

export const ToolCallMessageComponent: React.FC<{ message: AiMessage }> = ({ message }) => {
    const tool_calls = message?.additional_kwargs?.tool_calls
    return (
        <ErrorBoundary>
            <BaseToolContainer key={`message-${message.id}`}>
                {tool_calls && (
                    <Tooltip
                        title={
                            <div>
                                {tool_calls.map((tool, i: number) => (
                                    <div key={i}>
                                        <strong>Type:</strong> {tool.type}
                                        <br />
                                        <strong>Args:</strong>{' '}
                                        {tool_calls[0].function.name !== 'get_my_cohort'
                                            ? tool.function.arguments
                                            : JSON.stringify({ cohort_id: JSON.parse(tool.function.arguments).cohort_id, user_token: 'REDACTED' })}
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <BuildIcon />
                            <span>{tool_calls[0].function.name}</span>
                        </div>
                    </Tooltip>
                )}
            </BaseToolContainer>
        </ErrorBoundary>
    )
}

export const ToolCallResponseComponent: React.FC<{ message: ToolMessage }> = ({ message }) => {
    return (
        <ErrorBoundary>
            <BaseToolContainer key={`message-${message.id}`} style={{ opacity: 0.7 }}>
                <Tooltip
                    title={
                        <div>
                            <strong>Tool:</strong> {`${message.name}`}
                            <br />
                            <strong>Response:</strong> {`${message.status}`}
                        </div>
                    }
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            fontSize: '0.85em',
                            color: '#666',
                            fontStyle: 'italic'
                        }}
                    >
                        <BuildIcon fontSize='small' />
                        <span>{message.name} response received</span>
                    </div>
                </Tooltip>
            </BaseToolContainer>
        </ErrorBoundary>
    )
}
