/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CountDistinctQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/CountDistinctQualifierDTO_Output'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC } from 'react'
import { NodeContent } from '../nodes/NodeContent'

export const tableMap: Record<string, string> = {
    patient_diagnosis: 'diagnosis',
    patient_lab: 'lab',
    patient_medication: 'medication',
    patient_note: 'note',
    patient_external_cohort: 'external cohort',
    observation_period: 'observation period',
    patient_observation: 'observation',
    patient_procedure: 'procedure'
}

export const CountDistinctQualifier: FC<{
    qualifier: CountDistinctQualifierDTO_Output
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onClick?: (blockId: number, qualifierIndex?: number) => void
    table?: string
    disableClick?: boolean
    qualifierIndex?: number
}> = ({ qualifier, depth, parent, onClick, table, disableClick = false, qualifierIndex = -1 }) => {
    return (
        <NodeContent
            id={String(parent.blockId)}
            parentType={qualifier.type}
            onClick={() => {
                onClick && parent?.blockId && onClick(parent?.blockId, qualifierIndex)
            }}
            isQualifier
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
        >
            <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>
                <div>{`At least ${qualifier.value} distinct ${(table && tableMap[table]) || table}${qualifier.value > 1 ? 's' : ''}`}</div>
            </div>
        </NodeContent>
    )
}
