import { Trans } from '@lingui/macro'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FunctionComponent } from 'react'
import { CohortListItem } from '../../../state'
import { CohortSizeWithLoader } from '../../shared/CohortSizeWithLoader'

export interface PatientCountProps {
    cohort: CohortListItem
    loading?: boolean
}

/**
 * A component that displays the current size of a cohort with a label
 */
export const PatientCount: FunctionComponent<PatientCountProps> = (props) => {
    const { cohort, loading } = props
    return (
        <Box>
            <Box>
                <Typography fontSize={12} fontWeight='bold' textAlign='end' textTransform='uppercase'>
                    <Trans>Patient Count</Trans>
                </Typography>
            </Box>
            <Box component='span' fontSize={24}>
                <CohortSizeWithLoader
                    cohortSize={cohort.cohortSize}
                    datasetSize={cohort.analyticsDataset?.size}
                    isStale={cohort.isStale}
                    sizeLoading={loading}
                    justifyEnd={true}
                    isSystem={cohort.isSystem}
                />
            </Box>
        </Box>
    )
}
