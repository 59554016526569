/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { AndDTO_Output, FilterDTO_Output, OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { NodeContent } from '../nodes/NodeContent'
import { PatientOrGroup } from './PatientOrGroup'

export const PatientAndGroup: FC<{
    filter: AndDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, onClick, selectedBlockId }) => {
    const fieldValuesMap = {
        [(filter.and[0] as FilterDTO_Output).field]: (filter.and[0] as FilterDTO_Output).values,
        [(filter.and[1] as FilterDTO_Output).field]: (filter.and[1] as FilterDTO_Output).values
    }
    const { sex, race } = fieldValuesMap
    const { disabled } = filter
    const orNode = filter.and[2] as OrDTO_Output
    return (
        <NodeContent
            disabled={Boolean(disabled)}
            id={String(filter.blockId)}
            parentType={filter.type}
            onClick={onClick}
            selectedBlockId={String(selectedBlockId)}
        >
            <FilterHeader icon={CARD_ICONS.patient_attributes} title='Patient Attributes' />
            <div style={{ marginBottom: '0.25rem', fontSize: '0.75rem' }}>
                {sex && (
                    <div>
                        <span style={{ fontWeight: '800' }}>Sex:</span> {sex.join(', ') === '' ? 'Any' : sex.join(', ')}
                    </div>
                )}
                {race && (
                    <div>
                        <span style={{ fontWeight: '800' }}>Race:</span> {race.join(', ') === '' ? 'Any' : race.join(', ')}
                    </div>
                )}
                <PatientOrGroup node={orNode} depth={depth + 1} onClick={onClick} selectedBlockId={selectedBlockId} />
            </div>
        </NodeContent>
    )
}
