import { GetNextPageParamFunction, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { falconApiConfig } from '../../falcon-api-config'
import { CohortCreateDTO, CohortPatchDTO, ExplorerCohortsService, ExplorerTasksService, OpenAPI, PaginatedDTO_CohortDTO_ } from '../client'

export const QUERY_KEYS = {
    cohorts: 'cohorts',
    cohort: 'cohort',
    cohortSql: 'cohortSql',
    cohortReport: 'cohortReport',
    cohortSize: 'cohortSize'
} as const

interface UseCohortsInfiniteParams {
    isSystem?: boolean
    query?: string
    limit?: number
    initialData?: any
}

export function useCohortsInfinite({ isSystem = false, query, limit = 10, initialData }: UseCohortsInfiniteParams = {}) {
    const getNextPageParam: GetNextPageParamFunction<PaginatedDTO_CohortDTO_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    return useInfiniteQuery<PaginatedDTO_CohortDTO_>({
        queryKey: [QUERY_KEYS.cohorts, { isSystem, query, limit }],
        queryFn: ({ pageParam = 1 }) =>
            ExplorerCohortsService.indexExplorerCohortsGet({
                isSystem,
                query,
                page: pageParam,
                limit
            }),
        getNextPageParam,
        initialData
    })
}

export function useCohort(cohortId: string, isRefreshing) {
    return useQuery({
        queryKey: [QUERY_KEYS.cohort, cohortId],
        queryFn: () => ExplorerCohortsService.getExplorerCohortsCohortIdGet({ cohortId }),
        enabled: isRefreshing
    })
}

export function useCreateCohort() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: CohortCreateDTO) =>
            ExplorerCohortsService.createExplorerCohortsPost({
                requestBody: data
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.cohorts] })
        }
    })
}

export function useUpdateCohort() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ cohortId, data }: { cohortId: string; data: CohortPatchDTO }) =>
            ExplorerCohortsService.patchExplorerCohortsCohortIdPatch({
                cohortId,
                requestBody: data
            }),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.cohorts] })
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.cohort, variables.cohortId] })
        }
    })
}

export function useDeleteCohort() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (cohortId: string) => ExplorerCohortsService.deleteExplorerCohortsCohortIdDelete({ cohortId }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.cohorts] })
        }
    })
}

export function useCohortSql(cohortId: string) {
    return useQuery({
        queryKey: [QUERY_KEYS.cohortSql, cohortId],
        queryFn: () => ExplorerCohortsService.distinctPatientsSqlExplorerCohortsCohortIdSqlGet({ cohortId })
    })
}

export function useCohortReport(cohortId: string) {
    return useQuery({
        queryKey: [QUERY_KEYS.cohortReport, cohortId],
        queryFn: () => ExplorerCohortsService.getSummaryReportDataExplorerCohortsCohortIdSummaryReportDataGet({ cohortId })
    })
}

export function useRefreshCohortSize(cohortId: string, setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>, isRefreshing: boolean) {
    const queryClient = useQueryClient()
    const wsRef = useRef<WebSocket | null>(null)

    const { data } = useQuery({
        queryKey: [QUERY_KEYS.cohortSize, cohortId],
        queryFn: () => ExplorerTasksService.cohortUpdateSizeExplorerTasksCohortCohortIdUpdateSizeGet({ cohortId }),
        enabled: isRefreshing
    })

    useEffect(() => {
        if (data?.taskId) {
            const websocketUrl = `${falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')}/tasks/ws/${data.taskId}`
            const ws = new WebSocket(websocketUrl)
            wsRef.current = ws

            ws.onopen = () => {
                ws.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
            }

            ws.onmessage = (event) => {
                const newMessage = JSON.parse(event.data)
                if (newMessage.status?.toLowerCase() === 'success') {
                    console.log('is refreshing')
                    setIsRefreshing(false)
                    queryClient.refetchQueries({ queryKey: [QUERY_KEYS.cohort, cohortId] })
                }
            }

            ws.onclose = () => {
                console.log('WebSocket connection closed')
                setIsRefreshing(false)
            }

            return () => {
                ws.close()
            }
        }
    }, [cohortId, data, queryClient, setIsRefreshing])
}
