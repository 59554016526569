/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Button, MenuItem, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import TextField from '@mui/material/TextField/TextField'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'

import { PatientAgeQualifierDTO_Output } from '@om1/falcon-api'
import { FC, useEffect, useMemo, useState } from 'react'

export const determineLabel = (
    node: PatientAgeQualifierDTO_Output
): 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between' | undefined => {
    const { intervalStart, intervalEnd, intervalIsInclusive } = node
    if (intervalStart !== null && intervalEnd !== null && intervalIsInclusive) {
        return 'Exactly'
    } else if (intervalStart !== null && intervalEnd === null && intervalIsInclusive) {
        return 'At least'
    } else if (intervalStart === null && intervalEnd !== null && intervalIsInclusive) {
        return 'At most'
    } else if (intervalStart !== null && intervalEnd === null && !intervalIsInclusive) {
        return 'More than'
    } else if (intervalStart === null && intervalEnd !== null && !intervalIsInclusive) {
        return 'Less than'
    } else {
        return 'At any time between'
    }
}

export const getNodeFromLabel = (
    label: 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between',
    amount: number
): Partial<PatientAgeQualifierDTO_Output> => {
    switch (label) {
        case 'At most':
            return {
                intervalStart: null,
                intervalEnd: amount,
                intervalIsInclusive: true,
                intervalUnit: 'year'
            }
        case 'At least':
            return {
                intervalStart: amount,
                intervalEnd: null,
                intervalIsInclusive: true,
                intervalUnit: 'year'
            }
        case 'Exactly':
            return {
                intervalStart: amount,
                intervalEnd: amount,
                intervalIsInclusive: true,
                intervalUnit: 'year'
            }
        case 'Less than':
            return {
                intervalStart: amount,
                intervalEnd: null,
                intervalIsInclusive: false,
                intervalUnit: 'year'
            }
        case 'More than':
            return {
                intervalStart: amount,
                intervalEnd: null,
                intervalIsInclusive: false,
                intervalUnit: 'year'
            }
        case 'At any time between':
            return {
                intervalStart: amount,
                intervalEnd: amount,
                intervalIsInclusive: true,
                intervalUnit: 'year'
            }
        default:
            return {}
    }
}

export const PatientAgeQualifierDialogContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output }> = ({ editBlock }) => {
    const qualifier = useMemo(() => {
        return (editBlock.qualifiers as unknown as any[]).find((qualifier) => qualifier.type === 'PatientAgeQualifierDTO')
    }, [editBlock])

    const [initialState, setInitialState] = useState(qualifier)
    const [currentState, setCurrentState] = useState(qualifier)
    useEffect(() => {
        setInitialState(qualifier)
        setCurrentState(qualifier)
    }, [qualifier])

    const hasChanged = JSON.stringify(initialState) !== JSON.stringify(currentState)
    const currentLabel = useMemo(() => {
        const label = determineLabel(currentState)
        return label || ''
    }, [currentState])

    const updateLabel = (label: 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between') => {
        const amount = currentState.intervalStart || currentState.intervalEnd
        const node = getNodeFromLabel(label, Number(amount))
        setCurrentState({ ...currentState, ...node })
    }
    const isBetween = useMemo(() => {
        const result = currentState?.dateRangeOperator === 'Between'
        return result
    }, [currentState])

    const adjustedDate = new Date(currentState.referenceDate)
    adjustedDate.setDate(adjustedDate.getDate() + (currentState.intervalEnd || 0))

    return (
        <>
            <DialogContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    padding: '24px',
                    paddingTop: '40px',
                    overflowY: 'auto'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', gap: '16px' }}>
                    <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 600, lineHeight: '56px' }}>
                        Patient Age at Encounter(s)
                    </Typography>
                    <TextField
                        select
                        value={isBetween ? 'At any time between' : currentLabel}
                        onChange={(e) => {
                            if (e.target.value === 'At any time between') {
                                setCurrentState({ ...currentState, dateRangeOperator: 'Between' })
                                updateLabel('At any time between')
                            } else {
                                setCurrentState({ ...currentState, dateRangeOperator: 'After' })
                                updateLabel(e.target.value as 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than')
                            }
                        }}
                        style={{ minWidth: 120 }}
                    >
                        <MenuItem value='At most'>At most</MenuItem>
                        <MenuItem value='At least'>At least</MenuItem>
                        <MenuItem value='Exactly'>Exactly</MenuItem>
                        <MenuItem value='Less than'>Less than</MenuItem>
                        <MenuItem value='More than'>More than</MenuItem>
                    </TextField>
                    {isBetween ? (
                        <>
                            <TextField
                                type='date'
                                value={currentState.referenceDate}
                                onChange={(e) => setCurrentState({ ...currentState, referenceDate: new Date(e.target.value) })}
                                style={{ width: 180 }}
                            />
                            <TextField
                                type='date'
                                value={adjustedDate.toISOString().split('T')[0]}
                                onChange={(e) => setCurrentState({ ...currentState, referenceDate: new Date(e.target.value) })}
                                style={{ width: 180 }}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                type='number'
                                value={
                                    currentState.intervalStart !== undefined
                                        ? currentState.intervalStart
                                        : currentState.intervalEnd !== undefined
                                        ? currentState.intervalEnd
                                        : ''
                                }
                                onChange={(e) => setCurrentState({ ...currentState, intervalStart: Number(e.target.value) })}
                                style={{ width: 120 }}
                            />
                            <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 600, lineHeight: '56px' }}>
                                Years Old
                            </Typography>
                        </>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', paddingTop: '16px' }}>
                    <Button variant='contained' color='primary' disabled={!hasChanged}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}
