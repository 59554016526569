import { put } from 'redux-saga/effects'
import { Message } from '../lib/message'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { webSocketActions } from './websocket-sagas'

export function createWebSocketMessageReceivedSaga() {
    return function* (action: ReturnType<typeof webSocketActions.messageReceived>) {
        try {
            const message: Message = JSON.parse(action.payload)
            yield put(explorerAssistantConversationActions.appendResponse(message))
        } catch (error: any) {
            console.error(error)
        } finally {
            yield put(explorerAssistantConversationActions.setAssistantIsResponding(false))
        }
    }
}

export type WebSocketMessageReceivedSaga = ReturnType<typeof createWebSocketMessageReceivedSaga>
