import { ExplorerCohortsService, PaginatedDTO_CohortDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { put } from 'redux-saga/effects'
import { fetchDataWithCacheGenerator } from '../../../../apps/platform/src/query-utils'
import { CohortOwner, dataDeliveryActions } from '../state/data-delivery'

export function createGetCohortsSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.getCohorts>) {
        yield put(dataDeliveryActions.setCohortsLoading({ loading: true }))
        try {
            const paginatedUserCohortDTO: PaginatedDTO_CohortDTO_ = yield fetchDataWithCacheGenerator(
                {
                    isSystem: false,
                    page: 1,
                    limit: 1000
                },
                ExplorerCohortsService.indexExplorerCohortsGet
            )
            yield put(
                dataDeliveryActions.setCohorts({
                    cohorts: paginatedUserCohortDTO.data,
                    meta: paginatedUserCohortDTO.meta,
                    owner: CohortOwner.User
                })
            )
            const paginatedSystemCohortDTO: PaginatedDTO_CohortDTO_ = yield fetchDataWithCacheGenerator(
                {
                    isSystem: true,
                    page: 1,
                    limit: 1000
                },
                ExplorerCohortsService.indexExplorerCohortsGet
            )
            yield put(
                dataDeliveryActions.setCohorts({
                    cohorts: paginatedSystemCohortDTO.data,
                    meta: paginatedSystemCohortDTO.meta,
                    owner: CohortOwner.System
                })
            )
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.setCohortsLoading({ loading: false }))
        }
    }
}

export type GetCohortsSaga = ReturnType<typeof createGetCohortsSaga>
