/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { useDroppable } from '@dnd-kit/core'
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

export const QueryNode: FC<{
    children: React.ReactNode
    depth: number
    title?: string
    blockId?: number | null | undefined
    className?: string
    disabled?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
    and?: boolean
    or?: boolean
}> = ({ children, blockId, title = '', className, onClick, selectedBlockId, disabled, and, or, depth }) => {
    const { setNodeRef, isOver } = useDroppable({ id: blockId || 'no-block-id' })
    const location = useLocation()
    const isEditPath = location.pathname.includes('edit')
    const clickable = (!isEditPath || (depth > 1 && isEditPath)) && (!isEditPath || !or) && (!isEditPath || !and) && !disabled
    const selected = selectedBlockId === blockId && !(isNaN(Number(selectedBlockId)) && isNaN(Number(blockId)))
    const hoverStyle = blockId && isOver && !disabled ? { borderStyle: 'solid', borderColor: and ? '#4299e1' : '#ed8936' } : {}
    return (
        <div>
            <div
                ref={blockId ? setNodeRef : undefined}
                style={{
                    cursor: clickable ? 'pointer' : undefined,
                    padding: '1rem',
                    transition: 'all 0.2s',
                    zIndex: 10,
                    position: 'sticky',
                    width: '100%',
                    backgroundColor: depth > 0 ? (or ? 'white' : '#F5F5F5') : 'transparent',
                    boxShadow: depth > 0 ? '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' : '',
                    // border: selected
                    //     ? or
                    //         ? '1px solid gray'
                    //         : and
                    //         ? '1px solid gray'
                    //         : '1px solid gray'
                    //     : or
                    //     ? '1px dotted gray'
                    //     : and
                    //     ? '1px dotted gray'
                    //     : '1px dotted gray',
                    ...hoverStyle,
                    ...(className && { className })
                }}
                onClick={(event) => {
                    event.stopPropagation()
                    if (clickable && onClick && blockId) {
                        onClick(blockId)
                    }
                }}
            >
                {blockId !== undefined && (
                    <div
                        style={{
                            marginTop: '-10px',
                            marginBottom: '5px',
                            fontSize: '0.75rem',
                            color: selected ? (or ? 'orange' : 'green') : 'gray',
                            opacity: selected ? 1 : 0.6
                        }}
                    >
                        {/* {or ? 'Or' : and ? 'And' : '\u00A0'} */}
                        {'\u00A0'}
                    </div>
                )}
                {title && <div style={{ fontWeight: '600', marginBottom: '1rem' }}>{title}</div>}
                {children}
            </div>
        </div>
    )
}
