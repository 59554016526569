import { DataDeliveryAdminOrganizationMetadataService, OrganizationMetadataDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { put } from 'redux-saga/effects'
import { fetchDataWithCacheGenerator } from '../../../../apps/platform/src/query-utils'
import { dataDeliveryActions } from '../state/data-delivery'

export function createGetOrganizationMetadataSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.getOrganizationMetadata>) {
        yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: true }))
        try {
            const organizationMetadata: OrganizationMetadataDTO = yield fetchDataWithCacheGenerator(
                {
                    organizationId: action.payload.organizationId!
                },
                DataDeliveryAdminOrganizationMetadataService.getDataDeliveryOrganizationMetadataOrganizationIdGet
            )
            yield put(dataDeliveryActions.setOrganizationMetadata({ data: organizationMetadata }))
        } catch (error: any) {
            console.log('error', JSON.stringify(error))
            if (error.status === 404) {
                yield put(
                    dataDeliveryActions.setOrganizationMetadata({
                        data: {
                            id: '',
                            organizationId: action.payload.organizationId!,
                            deliveryDatabase: '',
                            deliveryShareName: ''
                        }
                    })
                )
            } else {
                yield handleApiError(error)
            }
        } finally {
            yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: false }))
        }
    }
}

export type GetOrganizationMetadataSaga = ReturnType<typeof createGetOrganizationMetadataSaga>
