import { withRouter } from '@om1/platform-routing/WithRouter'
import { connectRedux } from '@om1/platform-utils'
import { ExplorerAssistantConversationState } from '../state'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { CohortQueryExplorerComponent } from './CohortQueryExplorerComponent'

export function createCohortQueryExplorer<TState extends { explorerAssistantConversation: ExplorerAssistantConversationState }>() {
    return connectRedux(
        withRouter(CohortQueryExplorerComponent, { namespace: 'cohort-assistant' }),
        (state: TState) => state.explorerAssistantConversation,
        { ...explorerAssistantConversationActions }
    )
}

export type CohortQueryExplorer = ReturnType<typeof createCohortQueryExplorer>
