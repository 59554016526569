import { Action } from '@om1/platform-utils'
import { AnalyticsBrowser, Options } from '@segment/analytics-next'
import React from 'react'
import { Middleware } from 'redux'

export type SegmentEvent = [string, object?]

export function track<T extends Action<string>>(action: T, segmentEvent: SegmentEvent): T {
    return {
        ...action,
        segmentEvent
    }
}

export function trackingEvent<E extends keyof Plan>(name: E, properties: Required<Plan>[E]['properties'] = {}): SegmentEvent {
    return [name, properties]
}

export enum TrackingPlanEvents {
    IDENTIFY = 'Identify',
    PAGE_VIEW = 'Page View',
    AUTHED_ECHO = 'Platform Authed Echo',
    CRITERIA_INSERT = 'Criteria Insert',
    OPERATION_INSERT = 'Operation Insert',
    CRITERIA_RELATION_UPDATE = 'Criteria Relation Update',
    CRITERIA_UPDATE = 'Criteria Update',
    CRITERIA_DELETE = 'Criteria Delete',
    CRITERIA_COPY = 'Criteria Copy',
    CRITERIA_MOVE = 'Criteria Move',
    OPERATION_UPDATE = 'Operation Update',
    EXCLUDED_UPDATE = 'Excluded Update',
    COHORT_CREATE = 'Cohort Create',
    EDIT_COHORT = 'Cohort Edit Clicked',
    COHORT_DUPLICATE = 'Cohort Duplicate',
    COHORT_DELETE = 'Cohort Delete',
    COHORT_DRAG_START = 'Cohort Drag Start',
    COHORT_DRAG_END = 'Cohort Drag End',
    EDIT_COHORT_SAVE = 'Cohort Save Clicked',
    COHORT_WIZARD_CREATE = 'Cohort Wizard Start'
}

type DefaultPlanProperties = {
    properties?: { [key: string]: any }
}

export function identify<E extends keyof Plan>(properties: Required<Plan>[E]['properties'] = {}): SegmentEvent {
    return [TrackingPlanEvents.IDENTIFY, properties]
}

export type Plan = {
    [key in TrackingPlanEvents]: DefaultPlanProperties
}

export interface TrackingMetadata {
    source?: string
    tenantId?: string
    environment?: string
    buildNumber?: string
}

export function generateKey(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

function sendSegmentEvent(analytics: AnalyticsBrowser, segmentEvent?: SegmentEvent, options?: Options) {
    if (analytics && segmentEvent) {
        const [name, properties] = segmentEvent
        if (name === TrackingPlanEvents.IDENTIFY) {
            analytics.identify(properties!['user_id'], { ...properties, ...options?.traits })
            analytics.page(
                undefined,
                undefined,
                {
                    location: { pathname: window.location.pathname, search: '', hash: '', key: generateKey(10) },
                    action: 'PUSH',
                    isFirstRendering: true
                },
                {
                    traits: {
                        email: properties!['email'],
                        org_id: properties!['org_id'],
                        org_name: properties!['org_name'],
                        user_id: properties!['user_id']
                    }
                }
            )
        } else {
            analytics.track(name, properties, options)
        }
    }
}

interface TrackedAction extends Action<string> {
    segmentEvent: SegmentEvent
}

export function createTrackingMiddleware(analytics?: AnalyticsBrowser): Middleware {
    return (store) =>
        (next) =>
        ({ segmentEvent, ...action }: TrackedAction) => {
            if (analytics && segmentEvent) {
                const state = store.getState()
                sendSegmentEvent(analytics, segmentEvent, {
                    traits: {
                        environment: state.platformConfig?.environmentTag,
                        buildNumber: state.platformConfig?.buildNumber
                    }
                })
            }
            next(action)
        }
}

export const AnalyticsContext = React.createContext<AnalyticsBrowser | undefined>(undefined!)

export const useAnalytics = (writeKey: string | undefined) => {
    const result = React.useContext(AnalyticsContext)
    if (!result && writeKey && process.env.NODE_ENV === 'production') {
        throw new Error('Context used outside of its Provider!')
    } else {
        return result
    }
}
