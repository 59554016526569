/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateQualifierDTO_Output, FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { QueryNode } from '../nodes/QueryNode'
import { Qualifier } from '../qualifiers/Qualifier'

export const GenericPatientFilter: FC<{
    filter: FilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
    table?: string
}> = ({ filter, depth, onClick, selectedBlockId, table }) => {
    return (
        <QueryNode
            depth={depth}
            title={`${filter.table} filter ${filter.blockId}`}
            blockId={filter.blockId}
            onClick={onClick}
            selectedBlockId={selectedBlockId}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                        gap: '0.25rem',
                        fontSize: '1rem',
                        overflow: 'hidden'
                    }}
                >
                    <span style={{ color: '#4B5563' }}>Field:</span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '10rem' }}>{filter.field}</span>
                    <span style={{ color: '#4B5563' }}>Operator:</span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '10rem' }}>{filter.operator}</span>
                    <span style={{ color: '#4B5563' }}>Values:</span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '10rem' }}>
                        {Array.isArray(filter.values) ? filter.values.join(', ') : filter.values}
                    </span>
                    {filter.qualifiers && (filter.qualifiers as DateQualifierDTO_Output[]).length > 0 && (
                        <>
                            <div>
                                {(filter.qualifiers as unknown as DateQualifierDTO_Output[]).map((q: DateQualifierDTO_Output, index: number) => {
                                    return (
                                        <Qualifier
                                            key={`${filter.blockId}-${index}`}
                                            qualifier={q}
                                            depth={depth + 1}
                                            parent={filter}
                                            onClick={onClick}
                                            table={table}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </QueryNode>
    )
}
