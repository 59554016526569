import { AssistantConversationDTO, ExplorerAssistantConversationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'

export function createLaunchNewExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.launchNewConversation>) {
        try {
            const { query, ...payload } = action.payload
            const assistantConversationDTO: AssistantConversationDTO = yield call(
                ExplorerAssistantConversationService.createExplorerAssistantConversationPost,
                { requestBody: payload }
            )
            // const cohortDTO: CohortDTO = yield call(
            //     ExplorerCohortsService.getExplorerCohortsCohortIdGet,
            //     { cohortId: assistantConversationDTO.assistantCohortId }
            // )
            // yield put(cohortEditActions.cohortUpdateQuery({
            //         ...cohortDTO,
            //         query: query as QueryFilters
            //     }
            // ))
            yield put(push(`/cohort-builder/assistant/conversation/${assistantConversationDTO.id}`))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(explorerAssistantConversationActions.setConversationsLoading(false))
        }
    }
}

export type LaunchNewExplorerAssistantConversationSaga = ReturnType<typeof createLaunchNewExplorerAssistantConversationSaga>
