import { Trans } from '@lingui/macro'
import { Box, styled, Typography } from '@mui/material'
import { CoreService } from '@om1/falcon-api'
import { Auth0State } from '@om1/platform-authentication'
import { PlatformConfigState } from '@om1/platform-config/state'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect, useState } from 'react'
import { fetchDataWithCache } from '../../../../apps/platform/src/query-utils'
import LinkinIcon from '../assets/linkedin_2x.png'
import TwitterIcon from '../assets/twitter_2x.png'

const Logo = styled('img')({
    borderRadius: '50px',
    width: '50px',
    height: '50px',
    padding: '5px'
})

export type FooterComponentProps = FrameworkComponentProps<Omit<Auth0State & PlatformConfigState, 'showHelpDialog'>, {}, {}>

export function createFooterComponent<TState extends { auth0: Auth0State; platformConfig: PlatformConfigState }>() {
    return connectRedux(FooterComponent, (state: TState) => {
        return { ...state.auth0, ...state.platformConfig }
    })
}

export type Footer = ReturnType<typeof createFooterComponent>

export const FooterComponent: FunctionComponent<FooterComponentProps> = ({ state, actions, props }) => {
    const [version, setVersion] = useState<string>('')
    const [alembicVersion, setAlembicVersion] = useState<string>('')
    const [analyticsDatabase, setAnalyticsDatabase] = useState<string>('')
    useEffect(() => {
        if (state.user) {
            fetchDataWithCache('explorerCohorts', {}, CoreService.versionVersionGet)
                .catch((error) => {
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    console.error('Error fetching version:', error)
                    setVersion('')
                    setAlembicVersion('')
                    setAnalyticsDatabase('')
                })
                .then((response) => {
                    if (response) {
                        setVersion(response.version)
                        setAlembicVersion(response.alembicVersion)
                        setAnalyticsDatabase(response.analyticsDatabase)
                    }
                })
        }
    }, [state.user])
    let frontendVersion = 'develop'
    if (state.buildNumber !== 'develop') {
        frontendVersion = state.semanticVersion.replace(/(.*\.)(.*\.)(.*)/, `$1$2${state.buildNumber}`)
    }
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const versionString = `frontend: ${frontendVersion}, backend: ${version}, db: ${alembicVersion}, env: ${state.environmentTag}, analytics db: ${analyticsDatabase}`
    const copyrightYear = new Date().getFullYear()
    return (
        <Box
            component='footer'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                background: 'white',
                padding: '5px 0px 35px 0px',
                minWidth: 'fit-content',
                marginLeft: '65px',
                zIndex: '1000'
            }}
        >
            <Typography
                variant='caption'
                color='initial'
                sx={{
                    height: '30px',
                    color: '#555559',
                    fontSize: '14px',
                    letterSpacing: '0',
                    lineHeight: '30px'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', minWidth: 'fit-content' }}>
                    <Box sx={{ minWidth: 'fit-content' }}>
                        {copyrightYear} <Trans> © Copyright OM1, All rights reserved.</Trans>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', minWidth: 'fit-content' }}>
                        <Box
                            sx={{ marginLeft: '10px', marginRight: '10px', color: '#79A6D6', cursor: 'pointer' }}
                            onClick={() => {
                                window.open('https://www.om1.com/privacy/')
                            }}
                        >
                            <Trans>Privacy</Trans>
                        </Box>
                        |
                        <Box
                            sx={{ marginLeft: '10px', marginRight: '10px', color: '#79A6D6', cursor: 'pointer' }}
                            onClick={() => {
                                window.open('https://www.om1.com/terms-of-use/')
                            }}
                        >
                            <Trans>Terms of Use</Trans>
                        </Box>
                        <Logo
                            sx={{ cursor: 'pointer' }}
                            src={LinkinIcon}
                            onClick={() => {
                                window.open('https://www.linkedin.com/company/om1-inc.')
                            }}
                        />
                        <Logo
                            sx={{ cursor: 'pointer' }}
                            src={TwitterIcon}
                            onClick={() => {
                                window.open('https://twitter.com/Om1Inc')
                            }}
                        />
                    </Box>
                </Box>
            </Typography>
            <Box
                display='flex'
                justifyContent='flex-end'
                marginBottom='-15px'
                marginTop='25px'
                lineHeight='0px'
                fontSize='10px'
                marginRight='50px'
                sx={{ cursor: 'default', opacity: '50%', minWidth: 'fit-content' }}
            >
                <Box>{versionString}</Box>
            </Box>
        </Box>
    )
}
