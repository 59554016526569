import { t } from '@lingui/macro'
import { AnalyticsDimensionParam, AnalyticsGroupRes, AnalyticsGroupingSetsRes } from '@om1/falcon-api'
import { notificationActions } from '@om1/platform-notifications'
import { EventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { ApiRequestOptions } from '@om1/falcon-api/codegen/client/core/ApiRequestOptions'
import {
    AnalyticsCategoryData,
    AnalyticsChartData,
    AnalyticsData,
    AnalyticsDimension,
    DataTypeAnalyticsData,
    DemographicsAnalyticsData,
    ReportAnalyticsType,
    cohortAnalyticsActions
} from '../state'
import { taskSocket } from './task-socket'

export const ParamToCohortAnalyticsDimension: Record<AnalyticsDimensionParam, AnalyticsDimension> = {
    race: AnalyticsDimension.Race,
    sex: AnalyticsDimension.Sex,
    current_age: AnalyticsDimension.Age,
    ethnicity: AnalyticsDimension.Ethnicity,
    state: AnalyticsDimension.State,
    diagnosis: AnalyticsDimension.Diagnosis,
    diagnosis_concept_id: AnalyticsDimension.DiagnosisConceptId,
    drug: AnalyticsDimension.Drug,
    route_of_admin: AnalyticsDimension.RouteOfAdmin,
    va_hierarchy: AnalyticsDimension.VaHierarchy,
    boc_cui: AnalyticsDimension.BocCui,
    boc_name: AnalyticsDimension.BocName,
    ndc_code: AnalyticsDimension.NdcCode,
    ndc_name: AnalyticsDimension.NdcName,
    observation: AnalyticsDimension.Observation,
    procedure: AnalyticsDimension.Procedure,
    procedure_concept_id: AnalyticsDimension.ProcedureConceptId,
    phenotype: AnalyticsDimension.Phenotype,
    product_custom_cohort: AnalyticsDimension.ProductCustomCohort
}

function convertFromApiFormat(response: AnalyticsGroupRes, reportType: ReportAnalyticsType): AnalyticsData {
    if (reportType === ReportAnalyticsType.DEMOGRAPHICS) {
        const resp = response as AnalyticsGroupingSetsRes
        const results = resp.sections[0].results[0]
        const data: DemographicsAnalyticsData = {
            [AnalyticsDimension.Age]: {},
            [AnalyticsDimension.Ethnicity]: {},
            [AnalyticsDimension.Race]: {},
            [AnalyticsDimension.Sex]: {},
            [AnalyticsDimension.State]: {}
        }
        Object.keys(results).forEach((k) => {
            const inner: AnalyticsCategoryData = {}
            results[k].data.forEach((d) => {
                inner[d[k]] = d.patient_count
            })
            data[ParamToCohortAnalyticsDimension[k]] = inner
        })
        return data
    } else if (reportType === ReportAnalyticsType.DATA_TYPE) {
        const data: DataTypeAnalyticsData = {}

        const resp = response as AnalyticsGroupingSetsRes
        const results = resp.sections[0].results[0]

        Object.keys(results).forEach((k) => {
            results[k].data.forEach((d) => {
                if (d[k] === 'true') {
                    data[k] = d['patient_count']
                }
            })
        })

        return data
    } else {
        return response as AnalyticsChartData
    }
}

export function* onTaskCreationError(params: { cohortId: string; reportType: ReportAnalyticsType; actions: typeof cohortAnalyticsActions }) {
    const { cohortId, reportType, actions } = params
    const mockTaskId = uuidv4()
    yield put(actions.getDimensions({ cohortId, reportType, taskId: mockTaskId }))
    yield put(actions.setDimensionsError({ cohortId, reportType, taskId: mockTaskId }))
}

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

export function* analyticsSocketListen(params: {
    cohortId: string
    reportType: ReportAnalyticsType
    actions: typeof cohortAnalyticsActions
    token: string | Resolver<string> | undefined
    taskId: string
}) {
    const { cohortId, reportType, actions, taskId, token } = params
    yield put(cohortAnalyticsActions.getDimensions({ cohortId, reportType, taskId }))

    try {
        const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, token)
        const socketResponse = JSON.parse(yield take(eventChannel))
        if (socketResponse.status === 'SUCCESS') {
            const result: AnalyticsChartData = socketResponse.result
            yield put(actions.setDimensions({ cohortId, reportType, taskId, data: convertFromApiFormat(result, reportType) }))
        } else if (socketResponse.status === 'FAILURE') {
            yield put(cohortAnalyticsActions.setDimensionsError({ cohortId, reportType, taskId }))
            yield put(notificationActions.error(t`Error fetching data for report: ` + reportType))
        } else {
            yield put(actions.setDimensionsError({ cohortId, reportType, taskId }))
        }
    } catch (e) {
        yield put(actions.setDimensionsError({ cohortId, reportType, taskId }))
        console.error(e)
    }
}
