import { FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { GenericPatientFilter } from './GenericPatientFilter'

export const PatientEncounterFilter: FC<{
    filter: FilterDTO_Output
    depth: number
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, onClick, selectedBlockId }) => {
    return <GenericPatientFilter filter={filter} depth={depth} onClick={onClick} selectedBlockId={selectedBlockId} table={filter.table} />
}
