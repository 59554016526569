/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { PaginatedDictDTO_str_Any_ } from '@om1/falcon-api/codegen/client/models/PaginatedDictDTO_str_Any_'
import { RefTable } from '@om1/falcon-api/codegen/client/models/RefTable'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import { FC, useCallback, useEffect, useState } from 'react'
import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { NodeContent } from '../nodes/NodeContent'
import { fetchData } from '../queryUtils'
import { GenericFilterContent } from './GenericDateAwareFilter'

export const PatientDiagnosisFilter: FC<{
    filter: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    isSubject?: boolean
    isReference?: boolean
    onClick?: (blockId: number, qualifierIndex?: number) => void
    selectedBlockId?: number
}> = ({ filter, depth, isSubject, isReference, onClick, selectedBlockId }) => {
    const disabled = filter.disabled
    const { refs, fetchRefs } = useFetchRefs()

    useEffect(() => {
        fetchRefs(filter)
    }, [filter, fetchRefs])

    return (
        <NodeContent
            disabled={Boolean(disabled)}
            id={String(filter.blockId)}
            parentType={filter.type}
            allowRelateDrop={!isSubject && !isReference}
            disableAndDrop={isSubject || isReference}
            onClick={onClick}
            selectedBlockId={String(selectedBlockId)}
        >
            <FilterHeader icon={CARD_ICONS.diagnosis} title='Diagnosis' />
            <GenericFilterContent
                filter={filter}
                depth={depth}
                onClick={onClick}
                refs={refs}
                table={filter.table}
                isSubject={isSubject}
                isReference={isReference}
            />
        </NodeContent>
    )
}

export const useFetchRefs = () => {
    const [refs, setRefs] = useState<Array<Record<string, any>>>([])

    const processFilter = (filter: FilterDTO_Output | DateAwareFilterDTO_Output) => {
        const table = filter.table as string
        const values = filter.values as string[]

        if (!values?.length) return null

        const valuesStr = values.join(',')
        if (valuesStr.startsWith('boc') && table === 'patient_diagnosis') {
            return { refTableName: 'ref_diagnosis_boc_cui', valuesStr }
        } else if (valuesStr.startsWith('boc') && table === 'patient_medication') {
            return { refTableName: 'ref_medication_boc_cui', valuesStr }
        } else if (!valuesStr.includes('.') && table === 'patient_medication') {
            return { refTableName: 'ref_medication_ndc', valuesStr }
        } else if (table === 'patient_observation') {
            return { refTableName: 'ref_observation', valuesStr }
        } else if (table === 'observation_period') {
            return { refTableName: 'ref_observation_period', valuesStr }
        } else {
            return { refTableName: `ref_${table.replace('patient_', '')}`, valuesStr }
        }
    }

    const fetchRefs = useCallback(async (filter: FilterDTO_Output | DateAwareFilterDTO_Output) => {
        try {
            const processedFilter = processFilter(filter)
            if (!processedFilter || processedFilter.refTableName === 'ref_procedure') return

            const paginatedDict: PaginatedDictDTO_str_Any_ = await fetchData(
                'explorerCohorts',
                { refTableName: processedFilter.refTableName as RefTable, page: 1, limit: 100, query: null, filters: filter.values },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setRefs(paginatedDict.data)
        } catch (error) {
            console.error('Failed to fetch references:', error)
            // Optionally, handle the error or log it
        }
    }, [])

    return { refs, fetchRefs }
}
